import { v4 as uuidv4 } from 'uuid'
import { FormSectionLangFieldMap } from '@/types'
import { FieldType, SupportedLanguage } from '@/enum'
import { COMPLEXION_OPTIONS } from '../data/ComplexionOptions'
import { HEIGHT_OPTIONS } from '../data/HeightOptions'
import { BLOODGROUP_OPTIONS } from '../data/BloodGroupOptions'
import { RASHI_OPTIONS } from '../data/RashiOptions'
import { INCOME_OPTIONS } from '../data/IncomeOptions'
import { format } from 'date-fns'
import { NAKSHATRA_OPTIONS } from '../data/NakshatraOptions'
import { MARITALSTATUS_OPTIONS } from '../data/MaritalStatusOptions'
import { HOBBIES_OPTIONS } from '../data/HobbiesOptions'
import { LANGUAGES_OPTIONS } from '../data/LanguagesOptions'

export const PERSONAL_DETAILS_DATA: FormSectionLangFieldMap = {
  [SupportedLanguage.English]: [
    {
      id: uuidv4(),
      label: 'Name',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'Ankit Verma',
      identifier: 'name',
    },
    {
      id: uuidv4(),
      label: 'Date Of Birth',
      value: format(new Date(), 'dd MMMM yyyy'),
      type: FieldType.DATE,
      isHidden: false,
      isDefault: true,
      identifier: 'dateofbirth',
    },
    {
      id: uuidv4(),
      label: 'Time Of Birth',
      value: format(new Date(), 'hh:mm a'),
      type: FieldType.TIME,
      isHidden: false,
      isDefault: true,
      identifier: 'timeofbirth',
    },
    {
      id: uuidv4(),
      label: 'Place Of Birth',
      value: '',
      type: FieldType.TEXT_FIELD,
      placeholder: 'Mumbai',
      isHidden: false,
      isDefault: true,
      identifier: 'placeofbirth',
    },
    {
      id: uuidv4(),
      label: 'Marital Status',
      value: 'Never Married',
      type: FieldType.SELECT,
      placeholder: '',
      isHidden: true,
      isDefault: true,
      options: MARITALSTATUS_OPTIONS[SupportedLanguage.English],
      identifier: 'maritalstatus',
    },
    {
      id: uuidv4(),
      label: 'Complexion',
      value: 'Fair',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: COMPLEXION_OPTIONS[SupportedLanguage.English],
      identifier: 'complexion',
    },
    {
      id: uuidv4(),
      label: 'Height',
      value: '5\' 8"',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: HEIGHT_OPTIONS,
      identifier: 'height',
    },
    {
      id: uuidv4(),
      label: 'Blood Group',
      value: 'AB+',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      options: BLOODGROUP_OPTIONS[SupportedLanguage.English],
      identifier: 'bloodgroup',
    },
    {
      id: uuidv4(),
      label: 'Caste',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'Sonar / Sunar',
      identifier: 'caste',
    },
    {
      id: uuidv4(),
      label: 'Gothra',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'Kashyapa',
      identifier: 'gothra',
    },
    {
      id: uuidv4(),
      label: 'Mool',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'Chatrapuri',
      identifier: 'mool',
    },
    {
      id: uuidv4(),
      label: 'Mother Tongue',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: true,
      isDefault: true,
      placeholder: 'Hindi-Uttar Pradesh/Uttarakhand',
      identifier: 'mothertongue',
    },
    {
      id: uuidv4(),
      label: 'Rashi',
      value: 'Aries (Mesh)',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: RASHI_OPTIONS[SupportedLanguage.English],
      identifier: 'rashi',
    },
    {
      id: uuidv4(),
      label: 'Nakshatra',
      value: 'Ashwini',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      placeholder: 'Ashwini',
      options: NAKSHATRA_OPTIONS[SupportedLanguage.English],
      identifier: 'nakshatra',
    },
    {
      id: uuidv4(),
      label: 'Hobbies',
      value: [],
      type: FieldType.MULTI_SELECT,
      isHidden: false,
      isDefault: true,
      placeholder: 'Choose or type and press Enter',
      identifier: 'hobbies',
      options: HOBBIES_OPTIONS[SupportedLanguage.English],
    },
    {
      id: uuidv4(),
      label: 'Languages',
      value: [],
      type: FieldType.MULTI_SELECT,
      isHidden: true,
      isDefault: true,
      placeholder: 'Choose or type and press Enter',
      identifier: 'languages',
      options: LANGUAGES_OPTIONS[SupportedLanguage.English],
    },
    {
      id: uuidv4(),
      label: 'Education',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'B.Com. (Bachelor of Commerce)',
      identifier: 'education',
    },
    {
      id: uuidv4(),
      label: 'Specialization',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: true,
      isDefault: true,
      placeholder: 'Human Resource Management',
      identifier: 'specialization',
    },
    {
      id: uuidv4(),
      label: 'Occupation',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'Human Resources (HR)',
      identifier: 'occupation',
    },
    {
      id: uuidv4(),
      label: 'Work',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'ICICI Bank, Mumbai',
      identifier: 'work',
    },
    {
      id: uuidv4(),
      label: 'Income (Anually)',
      value: '₹ 5 - 7.5 Lakh',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      options: INCOME_OPTIONS[SupportedLanguage.English],
      identifier: 'income',
    },
  ],
  [SupportedLanguage.Hindi]: [
    {
      id: uuidv4(),
      label: 'नाम',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'अंकित वर्मा',
      identifier: 'name',
    },
    {
      id: uuidv4(),
      label: 'जन्म तिथि',
      value: format(new Date(), 'dd MMMM yyyy'),
      type: FieldType.DATE,
      isHidden: false,
      isDefault: true,
      identifier: 'dateofbirth',
    },
    {
      id: uuidv4(),
      label: 'जन्म का समय',
      value: format(new Date(), 'hh:mm a'),
      type: FieldType.TIME,
      isHidden: false,
      isDefault: true,
      identifier: 'timeofbirth',
    },
    {
      id: uuidv4(),
      label: 'जन्म स्थान',
      value: '',
      type: FieldType.TEXT_FIELD,
      placeholder: 'मुंबई',
      isHidden: false,
      isDefault: true,
      identifier: 'placeofbirth',
    },
    {
      id: uuidv4(),
      label: 'वैवाहिक स्थिति',
      value: 'अविवाहित',
      type: FieldType.SELECT,
      placeholder: '',
      isHidden: true,
      isDefault: true,
      options: MARITALSTATUS_OPTIONS[SupportedLanguage.Hindi],
      identifier: 'maritalstatus',
    },
    {
      id: uuidv4(),
      label: 'रंग',
      value: 'गोरा',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: COMPLEXION_OPTIONS[SupportedLanguage.Hindi],
      identifier: 'complexion',
    },
    {
      id: uuidv4(),
      label: 'कद',
      value: '5\' 8"',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: HEIGHT_OPTIONS,
      identifier: 'height',
    },
    {
      id: uuidv4(),
      label: 'रक्त समूह',
      value: 'AB+',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      options: BLOODGROUP_OPTIONS[SupportedLanguage.Hindi],
      identifier: 'bloodgroup',
    },
    {
      id: uuidv4(),
      label: 'जाति',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'सोनार / सुनार',
      identifier: 'caste',
    },
    {
      id: uuidv4(),
      label: 'गोत्र',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'कश्यप',
      identifier: 'gothra',
    },
    {
      id: uuidv4(),
      label: 'मूल',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'छत्रपुरी',
      identifier: 'mool',
    },
    {
      id: uuidv4(),
      label: 'मातृभाषा',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: true,
      isDefault: true,
      placeholder: 'हिंदी-उत्तर प्रदेश/उत्तराखंड',
      identifier: 'mothertongue',
    },
    {
      id: uuidv4(),
      label: 'राशि',
      value: 'मेष (Aries)',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: RASHI_OPTIONS[SupportedLanguage.Hindi],
      identifier: 'rashi',
    },
    {
      id: uuidv4(),
      label: 'नक्षत्र',
      value: 'अश्विनी',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      placeholder: 'अश्विनी',
      identifier: 'nakshatra',
      options: NAKSHATRA_OPTIONS[SupportedLanguage.Hindi],
    },
    {
      id: uuidv4(),
      label: 'शौक',
      value: [],
      type: FieldType.MULTI_SELECT,
      isHidden: false,
      isDefault: true,
      placeholder: 'चुनें या टाइप करें और एंटर दबाएं',
      identifier: 'hobbies',
      options: HOBBIES_OPTIONS[SupportedLanguage.Hindi],
    },
    {
      id: uuidv4(),
      label: 'भाषाएँ',
      value: [],
      type: FieldType.MULTI_SELECT,
      isHidden: true,
      isDefault: true,
      placeholder: 'चुनें या टाइप करें और एंटर दबाएं',
      identifier: 'languages',
      options: LANGUAGES_OPTIONS[SupportedLanguage.Hindi],
    },
    {
      id: uuidv4(),
      label: 'शिक्षा',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'बी.कॉम. (वाणिज्य स्नातक)',
      identifier: 'education',
    },
    {
      id: uuidv4(),
      label: 'विशेषज्ञता',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: true,
      isDefault: true,
      placeholder: 'मानव संसाधन प्रबंधन',
      identifier: 'specialization',
    },
    {
      id: uuidv4(),
      label: 'पेशा',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'मानव संसाधन (एचआर)',
      identifier: 'occupation',
    },
    {
      id: uuidv4(),
      label: 'व्यवसाय',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'ICICI बैंक, मुंबई',
      identifier: 'work',
    },
    {
      id: uuidv4(),
      label: 'आय (वार्षिक)',
      value: '₹ 5 - 7.5 लाख',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      options: INCOME_OPTIONS[SupportedLanguage.Hindi],
      identifier: 'income',
    },
  ],
  [SupportedLanguage.Marathi]: [
    {
      id: uuidv4(),
      label: 'नाव',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'अंकित वर्मा',
      identifier: 'name',
    },
    {
      id: uuidv4(),
      label: 'जन्मतारीख',
      value: format(new Date(), 'dd MMMM yyyy'),
      type: FieldType.DATE,
      isHidden: false,
      isDefault: true,
      identifier: 'dateofbirth',
    },
    {
      id: uuidv4(),
      label: 'जन्मवेळ',
      value: format(new Date(), 'hh:mm a'),
      type: FieldType.TIME,
      isHidden: false,
      isDefault: true,
      identifier: 'timeofbirth',
    },
    {
      id: uuidv4(),
      label: 'जन्मस्थान',
      value: '',
      type: FieldType.TEXT_FIELD,
      placeholder: 'मुंबई',
      isHidden: false,
      isDefault: true,
      identifier: 'placeofbirth',
    },
    {
      id: uuidv4(),
      label: 'वैवाहिक स्थिती',
      value: 'अविवाहित',
      type: FieldType.SELECT,
      placeholder: '',
      isHidden: true,
      isDefault: true,
      options: MARITALSTATUS_OPTIONS[SupportedLanguage.Marathi],
      identifier: 'maritalstatus',
    },
    {
      id: uuidv4(),
      label: 'त्वचा रंग',
      value: 'गोरा',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: COMPLEXION_OPTIONS[SupportedLanguage.Marathi],
      identifier: 'complexion',
    },
    {
      id: uuidv4(),
      label: 'उंची',
      value: '5\' 8"',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: HEIGHT_OPTIONS,
      identifier: 'height',
    },
    {
      id: uuidv4(),
      label: 'रक्तगट',
      value: 'AB+',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      options: BLOODGROUP_OPTIONS[SupportedLanguage.Marathi],
      identifier: 'bloodgroup',
    },
    {
      id: uuidv4(),
      label: 'जात',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'सोनार / सुनार',
      identifier: 'caste',
    },
    {
      id: uuidv4(),
      label: 'गोत्र',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'कश्यप',
      identifier: 'gothra',
    },
    {
      id: uuidv4(),
      label: 'मुळ',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'छत्रपुरी',
      identifier: 'mool',
    },
    {
      id: uuidv4(),
      label: 'मातृभाषा',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: true,
      isDefault: true,
      placeholder: 'हिंदी-उत्तर प्रदेश/उत्तराखंड',
      identifier: 'mothertongue',
    },
    {
      id: uuidv4(),
      label: 'राशी',
      value: 'मेष (Aries)',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: RASHI_OPTIONS[SupportedLanguage.Marathi],
      identifier: 'rashi',
    },
    {
      id: uuidv4(),
      label: 'नक्षत्र',
      value: 'अश्विनी',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      placeholder: 'अश्विनी',
      options: NAKSHATRA_OPTIONS[SupportedLanguage.Marathi],
      identifier: 'nakshatra',
    },
    {
      id: uuidv4(),
      label: 'छंद',
      value: [],
      type: FieldType.MULTI_SELECT,
      isHidden: false,
      isDefault: true,
      placeholder: 'निवडा किंवा टाइप करा आणि एंटर दाबा',
      identifier: 'hobbies',
      options: HOBBIES_OPTIONS[SupportedLanguage.Marathi],
    },
    {
      id: uuidv4(),
      label: 'भाषा',
      value: [],
      type: FieldType.MULTI_SELECT,
      isHidden: true,
      isDefault: true,
      placeholder: 'निवडा किंवा टाइप करा आणि एंटर दाबा',
      identifier: 'languages',
      options: LANGUAGES_OPTIONS[SupportedLanguage.Marathi],
    },
    {
      id: uuidv4(),
      label: 'शिक्षण',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'बी.कॉम. (वाणिज्य पदवी)',
      identifier: 'education',
    },
    {
      id: uuidv4(),
      label: 'विशेषज्ञता',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: true,
      isDefault: true,
      placeholder: 'मानव संसाधन व्यवस्थापन',
      identifier: 'specialization',
    },
    {
      id: uuidv4(),
      label: 'नोकरी/व्यवसाय',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'मानव संसाधन (HR)',
      identifier: 'occupation',
    },
    {
      id: uuidv4(),
      label: 'काम',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'ICICI बँक, मुंबई',
      identifier: 'work',
    },
    {
      id: uuidv4(),
      label: 'वेतन / उत्पन्न (वार्षिक)',
      value: '₹ ५ - ७.५ लाख',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      options: INCOME_OPTIONS[SupportedLanguage.Marathi],
      identifier: 'income',
    },
  ],
  [SupportedLanguage.Gujarati]: [
    {
      id: uuidv4(),
      label: 'નામ',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'અંકિત વર્મા',
      identifier: 'name',
    },
    {
      id: uuidv4(),
      label: 'જન્મ તારીખ',
      value: format(new Date(), 'dd MMMM yyyy'),
      type: FieldType.DATE,
      isHidden: false,
      isDefault: true,
      identifier: 'dateofbirth',
    },
    {
      id: uuidv4(),
      label: 'જન્મ સમય',
      value: format(new Date(), 'hh:mm a'),
      type: FieldType.TIME,
      isHidden: false,
      isDefault: true,
      identifier: 'timeofbirth',
    },
    {
      id: uuidv4(),
      label: 'જન્મ સ્થળ',
      value: '',
      type: FieldType.TEXT_FIELD,
      placeholder: 'મુંબઈ',
      isHidden: false,
      isDefault: true,
      identifier: 'placeofbirth',
    },
    {
      id: uuidv4(),
      label: 'વૈવાહિક સ્થિતિ',
      value: 'ક્યારેય લગ્ન નથી કર્યા',
      type: FieldType.SELECT,
      placeholder: '',
      isHidden: true,
      isDefault: true,
      options: MARITALSTATUS_OPTIONS[SupportedLanguage.Gujarati],
      identifier: 'maritalstatus',
    },
    {
      id: uuidv4(),
      label: 'રંગ',
      value: 'ગોરું',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: COMPLEXION_OPTIONS[SupportedLanguage.Gujarati],
      identifier: 'complexion',
    },
    {
      id: uuidv4(),
      label: 'ઊંચાઈ',
      value: '5\' 8"',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: HEIGHT_OPTIONS,
      identifier: 'height',
    },
    {
      id: uuidv4(),
      label: 'લોહીનું જૂથ',
      value: 'AB+',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      options: BLOODGROUP_OPTIONS[SupportedLanguage.Gujarati],
      identifier: 'bloodgroup',
    },
    {
      id: uuidv4(),
      label: 'જાતિ',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'સોનાર / સુનાર',
      identifier: 'caste',
    },
    {
      id: uuidv4(),
      label: 'ગોત્ર',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'કશ્યપ',
      identifier: 'gothra',
    },
    {
      id: uuidv4(),
      label: 'મૂળ',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'ચત્રપુરી',
      identifier: 'mool',
    },
    {
      id: uuidv4(),
      label: 'માતૃભાષા',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: true,
      isDefault: true,
      placeholder: 'હિન્દી-ઉત્તર પ્રદેશ/ઉત્તરાખંડ',
      identifier: 'mothertongue',
    },
    {
      id: uuidv4(),
      label: 'રાશિ',
      value: 'મેષ (Aries)',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: RASHI_OPTIONS[SupportedLanguage.Gujarati],
      identifier: 'rashi',
    },
    {
      id: uuidv4(),
      label: 'નક્ષત્ર',
      value: 'અશ્વિની',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      placeholder: 'અશ્વિની',
      options: NAKSHATRA_OPTIONS[SupportedLanguage.Gujarati],
      identifier: 'nakshatra',
    },
    {
      id: uuidv4(),
      label: 'શોખ',
      value: [],
      type: FieldType.MULTI_SELECT,
      isHidden: false,
      isDefault: true,
      placeholder: 'પસંદ કરો અથવા ટાઇપ કરો અને એન્ટર દબાવો',
      identifier: 'hobbies',
      options: HOBBIES_OPTIONS[SupportedLanguage.Gujarati],
    },
    {
      id: uuidv4(),
      label: 'ભાષાઓ',
      value: [],
      type: FieldType.MULTI_SELECT,
      isHidden: true,
      isDefault: true,
      placeholder: 'પસંદ કરો અથવા ટાઇપ કરો અને એન્ટર દબાવો',
      identifier: 'languages',
      options: LANGUAGES_OPTIONS[SupportedLanguage.Gujarati],
    },
    {
      id: uuidv4(),
      label: 'શિક્ષણ',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'બી.કોમ. (બેચલર ઓફ કોમર્સ)',
      identifier: 'education',
    },
    {
      id: uuidv4(),
      label: 'વિશેષતા',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: true,
      isDefault: true,
      placeholder: 'માનવ સંસાધન વ્યવસ્થાપન',
      identifier: 'specialization',
    },
    {
      id: uuidv4(),
      label: 'વ્યવસાય',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'માનવ સંસાધન (HR)',
      identifier: 'occupation',
    },
    {
      id: uuidv4(),
      label: 'કામ',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'ICICI બેંક, મુંબઈ',
      identifier: 'work',
    },
    {
      id: uuidv4(),
      label: 'આવક (વાર્ષિક)',
      value: '₹ 5 - 7.5 લાખ',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      options: INCOME_OPTIONS[SupportedLanguage.Gujarati],
      identifier: 'income',
    },
  ],
  [SupportedLanguage.Bengali]: [
    {
      id: uuidv4(),
      label: 'নাম',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'অঙ্কিত ভার্মা',
      identifier: 'name',
    },
    {
      id: uuidv4(),
      label: 'জন্ম তারিখ',
      value: format(new Date(), 'dd MMMM yyyy'),
      type: FieldType.DATE,
      isHidden: false,
      isDefault: true,
      identifier: 'dateofbirth',
    },
    {
      id: uuidv4(),
      label: 'জন্মসময়',
      value: format(new Date(), 'hh:mm a'),
      type: FieldType.TIME,
      isHidden: false,
      isDefault: true,
      identifier: 'timeofbirth',
    },
    {
      id: uuidv4(),
      label: 'জন্মস্থান',
      value: '',
      type: FieldType.TEXT_FIELD,
      placeholder: 'মুম্বাই',
      isHidden: false,
      isDefault: true,
      identifier: 'placeofbirth',
    },
    {
      id: uuidv4(),
      label: 'বৈবাহিক অবস্থা',
      value: 'কখনো বিয়ে হয়নি',
      type: FieldType.SELECT,
      placeholder: '',
      isHidden: true,
      isDefault: true,
      options: MARITALSTATUS_OPTIONS[SupportedLanguage.Bengali],
      identifier: 'maritalstatus',
    },
    {
      id: uuidv4(),
      label: 'বর্ণ',
      value: 'ফর্সা',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: COMPLEXION_OPTIONS[SupportedLanguage.Bengali],
      identifier: 'complexion',
    },
    {
      id: uuidv4(),
      label: 'উচ্চতা',
      value: '5\' 8"',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: HEIGHT_OPTIONS,
      identifier: 'height',
    },
    {
      id: uuidv4(),
      label: 'রক্তের গ্রুপ',
      value: 'AB+',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      options: BLOODGROUP_OPTIONS[SupportedLanguage.Bengali],
      identifier: 'bloodgroup',
    },
    {
      id: uuidv4(),
      label: 'জাতি',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'সোনার / সুনার',
      identifier: 'caste',
    },
    {
      id: uuidv4(),
      label: 'গোত্র',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'কশ্যপ',
      identifier: 'gothra',
    },
    {
      id: uuidv4(),
      label: 'মূল',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'চাত্রাপুরি',
      identifier: 'mool',
    },
    {
      id: uuidv4(),
      label: 'মাতৃভাষা',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: true,
      isDefault: true,
      placeholder: 'হিন্দি-উত্তরপ্রদেশ/উত্তরাখণ্ড',
      identifier: 'mothertongue',
    },
    {
      id: uuidv4(),
      label: 'রাশি',
      value: 'মেষ (Aries)',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: RASHI_OPTIONS[SupportedLanguage.Bengali],
      identifier: 'rashi',
    },
    {
      id: uuidv4(),
      label: 'নক্ষত্র',
      value: 'অশ্বিনী',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      placeholder: 'অশ্বিনী',
      options: NAKSHATRA_OPTIONS[SupportedLanguage.Bengali],
      identifier: 'nakshatra',
    },
    {
      id: uuidv4(),
      label: 'শখ',
      value: [],
      type: FieldType.MULTI_SELECT,
      isHidden: false,
      isDefault: true,
      placeholder: 'পছন্দ করুন অথবা টাইপ করুন এবং এন্টার প্রেস করুন',
      identifier: 'hobbies',
      options: HOBBIES_OPTIONS[SupportedLanguage.Bengali],
    },
    {
      id: uuidv4(),
      label: 'ভাষা',
      value: [],
      type: FieldType.MULTI_SELECT,
      isHidden: true,
      isDefault: true,
      placeholder: 'পছন্দ করুন অথবা টাইপ করুন এবং এন্টার প্রেস করুন',
      identifier: 'languages',
      options: LANGUAGES_OPTIONS[SupportedLanguage.Bengali],
    },
    {
      id: uuidv4(),
      label: 'শিক্ষা',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'বি.কম. (ব্যাচেলর অফ কমার্স)',
      identifier: 'education',
    },
    {
      id: uuidv4(),
      label: 'বিশেষায়িত ক্ষেত্র',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: true,
      isDefault: true,
      placeholder: 'হিউম্যান রিসোর্স ম্যানেজমেন্ট',
      identifier: 'specialization',
    },
    {
      id: uuidv4(),
      label: 'পেশা',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'হিউম্যান রিসোর্সেস (এইচআর)',
      identifier: 'occupation',
    },
    {
      id: uuidv4(),
      label: 'কর্মস্থল',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'আইসিআইসিআই ব্যাঙ্ক, মুম্বাই',
      identifier: 'work',
    },
    {
      id: uuidv4(),
      label: 'আয় (বার্ষিক)',
      value: '₹ ৫ - ৭.৫ লাখ',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      options: INCOME_OPTIONS[SupportedLanguage.Bengali],
      identifier: 'income',
    },
  ],
  [SupportedLanguage.Telugu]: [
    {
      id: uuidv4(),
      label: 'పేరు',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'అంకిత్ వర్మ',
      identifier: 'name',
    },
    {
      id: uuidv4(),
      label: 'జన్మ తేదీ',
      value: format(new Date(), 'dd MMMM yyyy'),
      type: FieldType.DATE,
      isHidden: false,
      isDefault: true,
      identifier: 'dateofbirth',
    },
    {
      id: uuidv4(),
      label: 'జన్మ సమయం',
      value: format(new Date(), 'hh:mm a'),
      type: FieldType.TIME,
      isHidden: false,
      isDefault: true,
      identifier: 'timeofbirth',
    },
    {
      id: uuidv4(),
      label: 'జన్మ స్థలం',
      value: '',
      type: FieldType.TEXT_FIELD,
      placeholder: 'ముంబై',
      isHidden: false,
      isDefault: true,
      identifier: 'placeofbirth',
    },
    {
      id: uuidv4(),
      label: 'వైవాహిక స్థితి',
      value: 'పెళ్లి చేసుకోలేదు',
      type: FieldType.SELECT,
      placeholder: '',
      isHidden: true,
      isDefault: true,
      options: MARITALSTATUS_OPTIONS[SupportedLanguage.Telugu],
      identifier: 'maritalstatus',
    },
    {
      id: uuidv4(),
      label: 'చర్మ వర్ణం',
      value: 'తెలుపు',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: COMPLEXION_OPTIONS[SupportedLanguage.Telugu],
      identifier: 'complexion',
    },
    {
      id: uuidv4(),
      label: 'ఎత్తు',
      value: '5\' 8"',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: HEIGHT_OPTIONS,
      identifier: 'height',
    },
    {
      id: uuidv4(),
      label: 'రక్త గ్రూపు',
      value: 'AB+',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      options: BLOODGROUP_OPTIONS[SupportedLanguage.Telugu],
      identifier: 'bloodgroup',
    },
    {
      id: uuidv4(),
      label: 'కులం',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'సోనార్ / సునార్',
      identifier: 'caste',
    },
    {
      id: uuidv4(),
      label: 'గోత్రం',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'కశ్యప',
      identifier: 'gothra',
    },
    {
      id: uuidv4(),
      label: 'మూల',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'చత్రపురి',
      identifier: 'mool',
    },
    {
      id: uuidv4(),
      label: 'మాతృభాష',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: true,
      isDefault: true,
      placeholder: 'హిందీ-ఉత్తర ప్రదేశ్/ఉత్తరాఖండ్',
      identifier: 'mothertongue',
    },
    {
      id: uuidv4(),
      label: 'రాశి',
      value: 'మేష (Aries)',
      type: FieldType.SELECT,
      isHidden: false,
      isDefault: true,
      options: RASHI_OPTIONS[SupportedLanguage.Telugu],
      identifier: 'rashi',
    },
    {
      id: uuidv4(),
      label: 'నక్షత్రం',
      value: 'అశ్విని',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      placeholder: 'అశ్విని',
      options: NAKSHATRA_OPTIONS[SupportedLanguage.Telugu],
      identifier: 'nakshatra',
    },
    {
      id: uuidv4(),
      label: 'అభిరుచులు',
      value: [],
      type: FieldType.MULTI_SELECT,
      isHidden: false,
      isDefault: true,
      placeholder: 'ఎంచుకోండి లేదా టైప్ చేసి ఎంటర్ నొక్కండి',
      identifier: 'hobbies',
      options: HOBBIES_OPTIONS[SupportedLanguage.Telugu],
    },
    {
      id: uuidv4(),
      label: 'భాషలు',
      value: [],
      type: FieldType.MULTI_SELECT,
      isHidden: true,
      isDefault: true,
      placeholder: 'ఎంచుకోండి లేదా టైప్ చేసి ఎంటర్ నొక్కండి',
      identifier: 'languages',
      options: LANGUAGES_OPTIONS[SupportedLanguage.Telugu],
    },
    {
      id: uuidv4(),
      label: 'విద్య',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'బి.కాం. (బ్యాచెలర్ ఆఫ్ కామర్స్)',
      identifier: 'education',
    },
    {
      id: uuidv4(),
      label: 'ప్రత్యేకత',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: true,
      isDefault: true,
      placeholder: 'హ్యూమన్ రిసోర్స్ మేనేజ్మెంట్',
      identifier: 'specialization',
    },
    {
      id: uuidv4(),
      label: 'వృత్తి',
      value: '',
      type: FieldType.AUTOCOMPLETE,
      isHidden: false,
      isDefault: true,
      placeholder: 'హ్యూమన్ రిసోర్సెస్ (HR)',
      identifier: 'occupation',
    },
    {
      id: uuidv4(),
      label: 'పని',
      value: '',
      type: FieldType.TEXT_FIELD,
      isHidden: false,
      isDefault: true,
      placeholder: 'ICICI బ్యాంక్, ముంబై',
      identifier: 'work',
    },
    {
      id: uuidv4(),
      label: 'ఆదాయం (వార్షికం)',
      value: '₹ 5 - 7.5 లక్షలు',
      type: FieldType.SELECT,
      isHidden: true,
      isDefault: true,
      options: INCOME_OPTIONS[SupportedLanguage.Telugu],
      identifier: 'income',
    },
  ],
}
