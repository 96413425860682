import { SupportedLanguage } from '@/enum'
import { DropdownOption } from '@/types'

export const MARITALSTATUS_OPTIONS: DropdownOption = {
  [SupportedLanguage.English]: ['Never Married', 'Currently Married', 'Awaiting Divorce', 'Divorced', 'Widowed', 'Separated', 'Annulled'],
  [SupportedLanguage.Hindi]: [
    'अविवाहित',
    'वर्तमान में विवाहित',
    'तलाक की प्रतीक्षा में',
    'तलाकशुदा',
    'विधवा/विधुर',
    'अलग हुए',
    'रद्द की गई शादी',
  ],
  [SupportedLanguage.Marathi]: [
    'अविवाहित',
    'सध्या विवाहित',
    'तलाकाची प्रतीक्षा',
    'घटस्फोटित',
    'विधवा/विधुर',
    'वेगळे झालेले',
    'रद्द केलेले लग्न',
  ],
  [SupportedLanguage.Gujarati]: [
    'ક્યારેય લગ્ન નથી કર્યા',
    'હાલમાં લગ્નિત',
    'છૂટાછેડાની રાહ જોઈ રહ્યા છે',
    'છૂટાછેડા થઈ ગયા',
    'વિધુર/વિધવા',
    'અલગ થઈ ગયા',
    'લગ્ન રદ કરી દીધા',
  ],
  [SupportedLanguage.Bengali]: [
    'কখনো বিয়ে হয়নি',
    'বর্তমানে বিবাহিত',
    'বিবাহ বিচ্ছেদের অপেক্ষায়',
    'বিচ্ছিন্ন',
    'বিধবা',
    'পৃথক',
    'বিবাহ বাতিল',
  ],
  [SupportedLanguage.Telugu]: ['పెళ్లి చేసుకోలేదు', 'ప్రస్తుతం వివాహం', 'విడాకుల కోసం వేచి ఉంది', 'విడాకులు', 'వితంతువు', 'వేరు', 'రద్దు'],
}
