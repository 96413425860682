'use client';

import Container from '@mui/material/Container';
import { FormFieldType, LanguageCode, LocalStorageFormData } from '@/types';
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';
import { FORM_DATA_LOCAL_STORAGE_KEY } from '@/constants/keys';
import { useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';
import TopAppBar from '../components/Layout/TopAppBar';
import FormSection from '../components/Form/FormSection';
import Footer from '../components/Layout/Footer';
import confetti from 'canvas-confetti';
import dynamic from 'next/dynamic';
import { PERSONAL_DETAILS_DATA } from '../api/data/PersonalDetailsData';
import { FAMILY_DETAILS_DATA } from '../api/data/FamilyDetailsData';
import { CONTACT_DETAILS_DATA } from '../api/data/ContactDetailsData';
import { getLocalStorageData } from '@/utils/StorageUtils';
import { WHITE } from '@/constants/colors';
import { generateWhatsAppMessage } from '@/utils/Utils';
import AdBanner from '../components/shared/AdBanner';

// Custom loading component that responds to screen size
const ResponsiveLoading = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  if (matches) {
    // Loading UI for screens smaller than 900px
    return <Box sx={{
      overflow: 'hidden',
      position: 'relative',
      height: '100%'
    }}>
        <Stack direction="row" gap={2} sx={{
        p: 2,
        boxShadow: 3,
        mb: 2
      }}>
          <Skeleton variant="rounded" width="100%" height={40} sx={{
          padding: '9px 16px'
        }} />
          <Skeleton variant="rounded" width="100%" height={40} sx={{
          padding: '9px 16px'
        }} />
        </Stack>
        <Stack direction="row" gap={2} sx={{
        p: 2
      }} flexWrap="wrap" justifyContent="space-between">
          <Skeleton variant="rounded" width="47%" height={245} />
          <Skeleton variant="rounded" width="47%" height={245} />
          <Skeleton variant="rounded" width="47%" height={245} />
          <Skeleton variant="rounded" width="47%" height={245} />
          <Skeleton variant="rounded" width="47%" height={245} />
          <Skeleton variant="rounded" width="47%" height={245} />
        </Stack>
        <Stack direction="row" gap={2} sx={{
        p: 2,
        boxShadow: 3,
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        background: WHITE
      }}>
          <Skeleton variant="rounded" width="100%" height={40} sx={{
          padding: '9px 16px'
        }} />
          <Skeleton variant="rounded" width="100%" height={40} sx={{
          padding: '9px 16px'
        }} />
        </Stack>
      </Box>;
  } else {
    // Default loading UI for larger screens
    return <Backdrop sx={{
      position: 'absolute',
      zIndex: theme => theme.zIndex.drawer + 1,
      background: 'rgba(256,256,256,0.2)'
    }} open={true}>
        <CircularProgress color="primary" />
      </Backdrop>;
  }
};

// Dynamic import with custom responsive loading component
const TemplateViewComponent = dynamic(() => import('../components/TemplateView/TemplateView'), {
  loading: ResponsiveLoading
});
const Home = () => {
  const [personalDetails, setPersonalDetails] = useState<FormFieldType[]>([]);
  const [familyDetails, setFamilyDetails] = useState<FormFieldType[]>([]);
  const [contactDetails, setContactDetails] = useState<FormFieldType[]>([]);
  const [biodataMessage, setBiodataMessage] = useState<string>('');
  const [open, setOpen] = useState(false);
  const pathname = usePathname();
  const locale = pathname.split('/')[1];
  const [openTemplateView, setOpenTemplateView] = useState<boolean>(false);
  // const router = useRouter()
  // const searchParams = useSearchParams()

  const t = useTranslations('HOMEPAGE');
  const handleModalClose = () => {
    setOpen(false);
  };
  const handleOpenTemplateView = () => {
    setOpenTemplateView(true);
    // updateUrlParams(true)
  };
  const handleCloseTemplateView = () => {
    setOpenTemplateView(false);
    // updateUrlParams(false)
  };
  const generateBiodataMessage = () => {
    setBiodataMessage('');
    const whatsAppMessage = generateWhatsAppMessage(personalDetails, familyDetails, contactDetails, t('PERSONAL_DETAILS_TITLE'), t('FAMILY_DETAILS_TITLE'), t('CONTACT_DETAILS_TITLE'));
    setBiodataMessage(whatsAppMessage);
    setOpen(true);
  };
  const copyClickHandler = async () => {
    await navigator.clipboard.writeText(biodataMessage || '');
    confetti({
      particleCount: 200,
      spread: 100,
      origin: {
        y: 0.75
      },
      zIndex: 9999
    });
    enqueueSnackbar('Copied to clipboard!', {
      variant: 'default',
      TransitionProps: {
        direction: 'up'
      }
    });
  };
  useEffect(() => {
    const fetchDetails = async () => {
      const storedData = getLocalStorageData(FORM_DATA_LOCAL_STORAGE_KEY);
      if (storedData && storedData[locale as LanguageCode]) {
        const {
          personalDetails,
          contactDetails,
          familyDetails
        } = storedData[locale as LanguageCode];
        if (personalDetails.length && contactDetails.length && familyDetails.length) {
          setPersonalDetails(personalDetails);
          setFamilyDetails(familyDetails);
          setContactDetails(contactDetails);
          return; // Data is valid, no need to fetch from API
        }
      }
      // Fetch from API if local data is not valid or not present
      fetchDataFromAPI();
    };
    const fetchDataFromAPI = () => {
      try {
        setPersonalDetails(PERSONAL_DETAILS_DATA[locale as LanguageCode]);
        setFamilyDetails(FAMILY_DETAILS_DATA[locale as LanguageCode]);
        setContactDetails(CONTACT_DETAILS_DATA[locale as LanguageCode]);
      } catch (error) {
        enqueueSnackbar('Something went wrong!', {
          variant: 'error',
          TransitionProps: {
            direction: 'up'
          }
        });
      }
    };
    fetchDetails();
  }, []);

  // useEffect(() => {
  //   const fetchDetails = async () => {
  //     const currentVersion = process.env.NEXT_PUBLIC_COMMIT_SHA || '1.0.0'
  //     const storedVersion = localStorage.getItem('appVersion')

  //     if (storedVersion !== currentVersion) {
  //       localStorage.removeItem(FORM_DATA_LOCAL_STORAGE_KEY)
  //       localStorage.setItem('appVersion', currentVersion) // Set new version immediately after clearing
  //       fetchDataFromAPI() // Fetch data from API since local storage was cleared // Todo: Uncomment to reset LC
  //     } else {
  //       const storedData = getLocalStorageData(FORM_DATA_LOCAL_STORAGE_KEY)
  //       if (storedData && storedData[locale as LanguageCode]) {
  //         const { personalDetails, contactDetails, familyDetails } = storedData[locale as LanguageCode]
  //         if (personalDetails.length && contactDetails.length && familyDetails.length) {
  //           setPersonalDetails(personalDetails)
  //           setFamilyDetails(familyDetails)
  //           setContactDetails(contactDetails)
  //           return // Data is valid, no need to fetch from API
  //         }
  //       }
  //       // Fetch from API if local data is not valid or not present
  //       fetchDataFromAPI()
  //     }
  //   }

  //   const fetchDataFromAPI = () => {
  //     try {
  //       // const [personalDetailsResponse, familyDetailsResponse, contactDetailsResponse] = await Promise.all([
  //       //   axios.get(`/api/personalDetails`, { params: { lang: locale } }),
  //       //   axios.get(`/api/familyDetails`, { params: { lang: locale } }),
  //       //   axios.get(`/api/contactDetails`, { params: { lang: locale } }),
  //       // ])

  //       setPersonalDetails(PERSONAL_DETAILS_DATA[locale as LanguageCode])
  //       setFamilyDetails(FAMILY_DETAILS_DATA[locale as LanguageCode])
  //       setContactDetails(CONTACT_DETAILS_DATA[locale as LanguageCode])
  //     } catch (error) {
  //       enqueueSnackbar('Something went wrong!', {
  //         variant: 'error',
  //         TransitionProps: { direction: 'up' },
  //       })
  //     }
  //   }

  //   fetchDetails()
  // }, [])

  useEffect(() => {
    const updateLocalStorage = (newFormData: LocalStorageFormData) => {
      localStorage.setItem(FORM_DATA_LOCAL_STORAGE_KEY, JSON.stringify(newFormData));
    };
    const storedData = localStorage.getItem(FORM_DATA_LOCAL_STORAGE_KEY);
    const formData = storedData ? JSON.parse(storedData) : {};
    const newFormDataForLang = {
      personalDetails,
      familyDetails,
      contactDetails
    };
    const updatedFormData = {
      ...formData,
      [locale]: newFormDataForLang
    };
    updateLocalStorage(updatedFormData);
  }, [personalDetails, familyDetails, contactDetails, locale]);

  // const updateUrlParams = useCallback(
  //   (isOpen: boolean) => {
  //     const currentParams = new URLSearchParams(searchParams.toString())

  //     if (isOpen) {
  //       currentParams.set('templateView', 'true')
  //     } else {
  //       currentParams.delete('templateView')
  //     }

  //     const newUrl = `${window.location.pathname}?${currentParams.toString()}`
  //     router.push(newUrl, { scroll: false })
  //   },
  //   [router, searchParams],
  // )

  // useEffect(() => {
  //   const templateParam = searchParams.get('templateView')
  //   if (templateParam === 'true' && !openTemplateView) {
  //     setOpenTemplateView(true)
  //   } else if (templateParam !== 'true' && openTemplateView) {
  //     setOpenTemplateView(false)
  //   }
  // }, [searchParams, openTemplateView])

  return <>
      <TopAppBar data-sentry-element="TopAppBar" data-sentry-source-file="page.tsx" />
      <Container maxWidth="lg" sx={{
      mt: 12,
      mb: 6
    }} data-sentry-element="Container" data-sentry-source-file="page.tsx">
        <FormSection title={t('PERSONAL_DETAILS_TITLE')} fields={personalDetails} setFields={setPersonalDetails} data-sentry-element="FormSection" data-sentry-source-file="page.tsx" />
        {/* <AdBanner
          dataAdSlot="2588405477"
          dataFullWidthResponsive={false}
          style={{ display: 'inline-block', width: '728px', height: '90px' }}
         /> */}
        <FormSection title={t('FAMILY_DETAILS_TITLE')} fields={familyDetails} setFields={setFamilyDetails} data-sentry-element="FormSection" data-sentry-source-file="page.tsx" />
        {/* <AdBanner
          dataAdSlot="4839982532"
          dataFullWidthResponsive={false}
          style={{ display: 'inline-block', width: '728px', height: '90px' }}
         /> */}
        <FormSection title={t('CONTACT_DETAILS_TITLE')} fields={contactDetails} setFields={setContactDetails} data-sentry-element="FormSection" data-sentry-source-file="page.tsx" />
        {/* <AdBanner
          dataAdSlot="3736322840"
          dataFullWidthResponsive={false}
          style={{ display: 'inline-block', width: '728px', height: '90px' }}
         /> */}
        <Stack direction="row" gap={2} sx={{
        flexWrap: {
          xs: 'wrap',
          sm: 'unset'
        }
      }} data-sentry-element="Stack" data-sentry-source-file="page.tsx">
          <Button variant="outlined" sx={{
          width: {
            xs: '100%',
            sm: '50%'
          }
        }} size="large" onClick={generateBiodataMessage} data-sentry-element="Button" data-sentry-source-file="page.tsx">
            {t('VIEW_BIODATA_DETAILS_BTN')}
          </Button>
          <Button variant="contained" sx={{
          width: {
            xs: '100%',
            sm: '50%'
          }
        }} size="large" onClick={handleOpenTemplateView} data-sentry-element="Button" data-sentry-source-file="page.tsx">
            {t('CHOOSE_TEMPLATE_BTN')}
          </Button>
        </Stack>
      </Container>
      <Footer data-sentry-element="Footer" data-sentry-source-file="page.tsx" />
      <Drawer open={openTemplateView} onClose={handleCloseTemplateView} anchor="bottom" PaperProps={{
      sx: {
        height: '100%'
      }
    }} data-sentry-element="Drawer" data-sentry-source-file="page.tsx">
        <TemplateViewComponent sectionOneTitle={t('PERSONAL_DETAILS_TITLE')} sectionTwoTitle={t('FAMILY_DETAILS_TITLE')} sectionThreeTitle={t('CONTACT_DETAILS_TITLE')} personalDetails={personalDetails} contactDetails={contactDetails} familyDetails={familyDetails} handleCloseTemplateView={handleCloseTemplateView} data-sentry-element="TemplateViewComponent" data-sentry-source-file="page.tsx" />
      </Drawer>
      <Dialog open={open} onClose={handleModalClose} maxWidth="sm" fullWidth data-sentry-element="Dialog" data-sentry-source-file="page.tsx">
        <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="page.tsx">{t('MODAL_TITLE')}</DialogTitle>
        <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="page.tsx">
          <DialogContentText dangerouslySetInnerHTML={{
          __html: biodataMessage.replaceAll('\n', '<br>').replace(/\*(.*?)\*/g, '<b>$1</b>')
        }} data-sentry-element="DialogContentText" data-sentry-source-file="page.tsx"></DialogContentText>
        </DialogContent>
        <DialogActions sx={{
        p: 2
      }} data-sentry-element="DialogActions" data-sentry-source-file="page.tsx">
          <Button onClick={handleModalClose} variant="outlined" data-sentry-element="Button" data-sentry-source-file="page.tsx">
            {t('MODAL_BUTTON_CLOSE')}
          </Button>
          <Button onClick={copyClickHandler} autoFocus variant="contained" data-sentry-element="Button" data-sentry-source-file="page.tsx">
            {t('MODAL_BUTTON_COPY')}
          </Button>
        </DialogActions>
      </Dialog>
    </>;
};
export default Home;