import { SupportedLanguage } from '@/enum'
import { DropdownOption } from '@/types'

export const RASHI_OPTIONS: DropdownOption = {
  [SupportedLanguage.English]: [
    'Aries (Mesh)',
    'Taurus (Vrishabh)',
    'Gemini (Mithun)',
    'Cancer (Karka)',
    'Leo (Simha)',
    'Virgo (Kanya)',
    'Libra (Tula)',
    'Scorpio (Vrishchik)',
    'Sagittarius (Dhanu)',
    'Capricorn (Makar)',
    'Aquarius (Kumbh)',
    'Pisces (Meen)',
  ],
  [SupportedLanguage.Hindi]: [
    'मेष (Aries)',
    'वृषभ (Taurus)',
    'मिथुन (Gemini)',
    'कर्क (Cancer)',
    'सिंह (Leo)',
    'कन्या (Virgo)',
    'तुला (Libra)',
    'वृश्चिक (Scorpio)',
    'धनु (Sagittarius)',
    'मकर (Capricorn)',
    'कुंभ (Aquarius)',
    'मीन (Pisces)',
  ],
  [SupportedLanguage.Marathi]: [
    'मेष (Aries)',
    'वृषभ (Taurus)',
    'मिथुन (Gemini)',
    'कर्क (Cancer)',
    'सिंह (Leo)',
    'कन्या (Virgo)',
    'तुळ (Libra)',
    'वृश्चिक (Scorpio)',
    'धनु (Sagittarius)',
    'मकर (Capricorn)',
    'कुंभ (Aquarius)',
    'मीन (Pisces)',
  ],
  [SupportedLanguage.Gujarati]: [
    'મેષ (Aries)',
    'વૃષભ (Taurus)',
    'મિથુન (Gemini)',
    'કર્ક (Cancer)',
    'સિંહ (Leo)',
    'કન્યા (Virgo)',
    'તુલા (Libra)',
    'વૃશ્ચિક (Scorpio)',
    'ધનુ (Sagittarius)',
    'મકર (Capricorn)',
    'કુંભ (Aquarius)',
    'મીન (Pisces)',
  ],
  [SupportedLanguage.Bengali]: [
    'মেষ (Aries)',
    'বৃষ (Taurus)',
    'মিথুন (Gemini)',
    'কর্কট (Cancer)',
    'সিংহ (Leo)',
    'কন্যা (Virgo)',
    'তুলা (Libra)',
    'বৃশ্চিক (Scorpio)',
    'ধনু (Sagittarius)',
    'মকর (Capricorn)',
    'কুম্ভ (Aquarius)',
    'মীন (Pisces)',
  ],
  [SupportedLanguage.Telugu]: [
    'మేష (Aries)',
    'వృషభ (Taurus)',
    'మిథున (Gemini)',
    'కర్కట (Cancer)',
    'సింహ (Leo)',
    'కన్యా (Virgo)',
    'తుల (Libra)',
    'వృశ్చిక (Scorpio)',
    'ధనుస్సు (Sagittarius)',
    'మకర (Capricorn)',
    'కుంభ (Aquarius)',
    'మీనం (Pisces)',
  ],
}
