import { SupportedLanguage } from '@/enum'
import { DropdownOption } from '@/types'

export const LANGUAGES_OPTIONS: DropdownOption = {
  [SupportedLanguage.English]: [
    'Arabic',
    'Assamese',
    'Bengali',
    'Bhojpuri',
    'Chinese',
    'Dutch',
    'English',
    'French',
    'German',
    'Gujarati',
    'Hindi',
    'Italian',
    'Japanese',
    'Kannada',
    'Kashmiri',
    'Konkani',
    'Korean',
    'Malayalam',
    'Marathi',
    'Nepali',
    'Odia',
    'Pashto',
    'Persian',
    'Polish',
    'Portuguese',
    'Punjabi',
    'Russian',
    'Sindhi',
    'Spanish',
    'Tamil',
    'Telugu',
    'Thai',
    'Turkish',
    'Urdu',
  ],
  [SupportedLanguage.Hindi]: [
    'अरबी',
    'असमिया',
    'बंगाली',
    'भोजपुरी',
    'चीनी',
    'डच',
    'अंग्रेज़ी',
    'फ्रेंच',
    'जर्मन',
    'गुजराती',
    'हिंदी',
    'इतालवी',
    'जापानी',
    'कन्नड़',
    'कश्मीरी',
    'कोंकणी',
    'कोरियाई',
    'मलयालम',
    'मराठी',
    'नेपाली',
    'ओड़िया',
    'पश्तो',
    'फारसी',
    'पोलिश',
    'पुर्तगाली',
    'पंजाबी',
    'रूसी',
    'सिंधी',
    'स्पेनिश',
    'तमिल',
    'तेलुगु',
    'थाई',
    'तुर्की',
    'उर्दू',
  ],
  [SupportedLanguage.Marathi]: [
    'अरबी',
    'आसामी',
    'बंगाली',
    'भोजपुरी',
    'चीनी',
    'डच',
    'इंग्रजी',
    'फ्रेंच',
    'जर्मन',
    'गुजराती',
    'हिंदी',
    'इटालियन',
    'जपानी',
    'कन्नड',
    'काश्मीरी',
    'कोंकणी',
    'कोरियन',
    'मल्याळम',
    'मराठी',
    'नेपाली',
    'ओडिया',
    'पश्तो',
    'फारसी',
    'पोलिश',
    'पोर्तुगीज',
    'पंजाबी',
    'रशियन',
    'सिंधी',
    'स्पॅनिश',
    'तामिळ',
    'तेलुगु',
    'थाई',
    'तुर्की',
    'उर्दू',
  ],
  [SupportedLanguage.Gujarati]: [
    'અરબી',
    'આસામી',
    'બંગાળી',
    'ભોજપુરી',
    'ચાઇનીઝ',
    'ડચ',
    'અંગ્રેજી',
    'ફ્રેંચ',
    'જર્મન',
    'ગુજરાતી',
    'હિન્દી',
    'ઇટાલિયન',
    'જાપાનીઝ',
    'કન્નડ',
    'કાશ્મીરી',
    'કોંકણી',
    'કોરિયન',
    'મલયાલમ',
    'મરાઠી',
    'નેપાળી',
    'ઓડિયા',
    'પશ્તો',
    'ફારસી',
    'પોલિશ',
    'પોર્ટુગીઝ',
    'પંજાબી',
    'રશિયન',
    'સિંધી',
    'સ્પેનિશ',
    'તમિલ',
    'તેલુગુ',
    'થાઈ',
    'તુર્કીશ',
    'ઉર્દૂ',
  ],
  [SupportedLanguage.Bengali]: [
    'আরবি',
    'অসমীয়া',
    'বাংলা',
    'ভোজপুরী',
    'চাইনিজ',
    'ডাচ',
    'ইংরেজি',
    'ফরাসি',
    'জার্মান',
    'গুজরাটি',
    'হিন্দি',
    'ইতালিয়ান',
    'জাপানি',
    'কন্নড়',
    'কাশ্মীরি',
    'কঙ্কনি',
    'কোরিয়ান',
    'মালয়ালম',
    'মারাঠি',
    'নেপালি',
    'ওড়িয়া',
    'পশতু',
    'ফারসি',
    'পোলিশ',
    'পর্তুগিজ',
    'পাঞ্জাবি',
    'রুশ',
    'সিন্ধি',
    'স্প্যানিশ',
    'তামিল',
    'তেলেগু',
    'থাই',
    'তুর্কী',
    'উর্দু',
  ],
  [SupportedLanguage.Telugu]: [
    'అరబిక్',
    'ఆసామీ',
    'బంగాళీ',
    'భోజ్‌పూరీ',
    'చైనీస్',
    'డచ్',
    'ఆంగ్లం',
    'ఫ్రెంచ్',
    'జర్మన్',
    'గుజరాతీ',
    'హిందీ',
    'ఇటాలియన్',
    'జపనీస్',
    'కన్నడ',
    'కశ్మీరీ',
    'కోంకణీ',
    'కొరియన్',
    'మలయాళం',
    'మరాఠీ',
    'నేపాలీ',
    'ఓడియా',
    'పష్తో',
    'పర్షియన్',
    'పోలిష్',
    'పోర్చుగీసు',
    'పంజాబీ',
    'రష్యన్',
    'సింధీ',
    'స్పానిష్',
    'తమిళం',
    'తెలుగు',
    'థాయ్',
    'టర్కిష్',
    'ఉర్దూ',
  ],
}
