import { SupportedLanguage } from '@/enum'
import { DropdownOption } from '@/types'

export const EDUCATION_OPTIONS: DropdownOption = {
  [SupportedLanguage.English]: [
    'SSC (Secondary School Certificate)',
    'HSC (Higher Secondary Certificate)',
    'Diploma',
    'B.A. (Bachelor of Arts)',
    'B.Sc. (Bachelor of Science)',
    'B.Com. (Bachelor of Commerce)',
    'B.Tech (Bachelor of Technology)',
    'B.E. (Bachelor of Engineering)',
    'MBBS (Bachelor of Medicine, Bachelor of Surgery)',
    'BDS (Bachelor of Dental Surgery)',
    'B.Arch (Bachelor of Architecture)',
    'M.A. (Master of Arts)',
    'M.Sc. (Master of Science)',
    'M.Com. (Master of Commerce)',
    'M.Tech (Master of Technology)',
    'M.E. (Master of Engineering)',
    'MBA (Master of Business Administration)',
    'MD (Doctor of Medicine)',
    'MS (Master of Surgery)',
    'Ph.D. (Doctor of Philosophy)',
  ],
  [SupportedLanguage.Hindi]: [
    'एसएससी (माध्यमिक विद्यालय प्रमाणपत्र)',
    'एचएससी (उच्च माध्यमिक प्रमाणपत्र)',
    'डिप्लोमा',
    'बी.ए. (कला स्नातक)',
    'बी.एससी. (विज्ञान स्नातक)',
    'बी.कॉम. (वाणिज्य स्नातक)',
    'बी.टेक (प्रौद्योगिकी स्नातक)',
    'बी.ई. (इंजीनियरिंग स्नातक)',
    'एमबीबीएस (चिकित्सा स्नातक)',
    'बीडीएस (दंत चिकित्सा स्नातक)',
    'बी.आर्क (वास्तुकला स्नातक)',
    'एम.ए. (कला स्नातकोत्तर)',
    'एम.एससी. (विज्ञान स्नातकोत्तर)',
    'एम.कॉम. (वाणिज्य स्नातकोत्तर)',
    'एम.टेक (प्रौद्योगिकी स्नातकोत्तर)',
    'एम.ई. (इंजीनियरिंग स्नातकोत्तर)',
    'एमबीए (व्यवसाय प्रशासन स्नातकोत्तर)',
    'एमडी (चिकित्सा स्नातकोत्तर)',
    'एमएस (शल्य चिकित्सा स्नातकोत्तर)',
    'पीएच.डी. (डॉक्टर ऑफ फिलॉसफी)',
  ],
  [SupportedLanguage.Marathi]: [
    'एसएससी (माध्यमिक शाळा प्रमाणपत्र)',
    'एचएससी (उच्च माध्यमिक प्रमाणपत्र)',
    'डिप्लोमा',
    'बी.ए. (कला शाखेतील पदवी)',
    'बी.एस्सी. (विज्ञान शाखेतील पदवी)',
    'बी.कॉम. (वाणिज्य शाखेतील पदवी)',
    'बी.टेक (तंत्रज्ञान शाखेतील पदवी)',
    'बी.ई. (अभियांत्रिकी शाखेतील पदवी)',
    'एमबीबीएस (वैद्यकीय शाखेतील पदवी)',
    'बीडीएस (दंतवैद्यक शाखेतील पदवी)',
    'बी.आर्क (वास्तुकला शाखेतील पदवी)',
    'एम.ए. (कला शाखेतील पदव्युत्तर)',
    'एम.एस्सी. (विज्ञान शाखेतील पदव्युत्तर)',
    'एम.कॉम. (वाणिज्य शाखेतील पदव्युत्तर)',
    'एम.टेक (तंत्रज्ञान शाखेतील पदव्युत्तर)',
    'एम.ई. (अभियांत्रिकी शाखेतील पदव्युत्तर)',
    'एमबीए (व्यवस्थापन शाखेतील पदव्युत्तर)',
    'एमडी (वैद्यकीय शाखेतील पदव्युत्तर)',
    'एमएस (शल्यचिकित्सा शाखेतील पदव्युत्तर)',
    'पीएच.डी. (तत्त्वज्ञान शाखेतील डॉक्टरेट)',
  ],
  [SupportedLanguage.Gujarati]: [
    'એસએસસી (માધ્યમિક શાળા પ્રમાણપત્ર)',
    'એચએસસી (ઉચ્ચ માધ્યમિક પ્રમાણપત્ર)',
    'ડિપ્લોમા',
    'બી.એ. (કલાસ્નાર્થી)',
    'બી.એસસી. (વિજ્ઞાનનો સ્નાતક)',
    'બી.કોમ. (વાણિજ્યનો સ્નાતક)',
    'બી.ટેક (ટેકનોલોજીનો સ્નાતક)',
    'બી.ઈ. (ઈજનેરીનો સ્નાતક)',
    'એમબીબીએસ (મેડિસિન અને સર્જરીનો સ્નાતક)',
    'બીડીએસ (ડેન્ટલ સર્જરીનો સ્નાતક)',
    'બી.આર્ક (આર્કિટેક્ચરનો સ્નાતક)',
    'એમ.એ. (કલાસ્નાર્થી)',
    'એમ.એસસી. (વિજ્ઞાનનો માસ્ટર)',
    'એમ.કોમ. (વાણિજ્યનો માસ્ટર)',
    'એમ.ટેક (ટેકનોલોજીનો માસ્ટર)',
    'એમ.ઈ. (ઈજનેરીનો માસ્ટર)',
    'એમબીએ (બિઝનેસ એડમિનિસ્ટ્રેશનનો માસ્ટર)',
    'એમડી (મેડિસિનનો ડોક્ટર)',
    'એમએસ (સર્જરીનો માસ્ટર)',
    'પીએચ.ડી. (દર્શનશાસ્ત્રનો ડોક્ટર)',
  ],
  [SupportedLanguage.Bengali]: [
    'এসএসসি (মাধ্যমিক স্কুল সার্টিফিকেট)',
    'এইচএসসি (উচ্চ মাধ্যমিক সার্টিফিকেট)',
    'ডিপ্লোমা',
    'বি.এ. (ব্যাচেলর অব আর্টস)',
    'বি.এসসি. (ব্যাচেলর অব সায়েন্স)',
    'বি.কম. (ব্যাচেলর অব কমার্স)',
    'বি.টেক (ব্যাচেলর অব টেকনোলজি)',
    'বি.ই. (ব্যাচেলর অব ইঞ্জিনিয়ারিং)',
    'এমবিবিএস (ব্যাচেলর অব মেডিসিন, ব্যাচেলর অব সার্জারি)',
    'বিডিএস (ব্যাচেলর অব ডেন্টাল সার্জারি)',
    'বি.আর্ক (ব্যাচেলর অব আর্কিটেকচার)',
    'এম.এ. (মাস্টার অব আর্টস)',
    'এম.এসসি. (মাস্টার অব সায়েন্স)',
    'এম.কম. (মাস্টার অব কমার্স)',
    'এম.টেক (মাস্টার অব টেকনোলজি)',
    'এম.ই. (মাস্টার অব ইঞ্জিনিয়ারিং)',
    'এমবিএ (মাস্টার অব বিজনেস অ্যাডমিনিস্ট্রেশন)',
    'এমডি (ডক্টর অব মেডিসিন)',
    'এমএস (মাস্টার অব সার্জারি)',
    'পিএইচ.ডি. (ডক্টর অব ফিলোসফি)',
  ],
  [SupportedLanguage.Telugu]: [
    'ఎస్సెస్సీ (సెకండరీ స్కూల్ సర్టిఫికేట్)',
    'హెచ్‌ఎస్సీ (హయ్యర్ సెకండరీ సర్టిఫికేట్)',
    'డిప్లొమా',
    'బి.ఎ. (బాచిలర్ ఆఫ్ ఆర్ట్స్)',
    'బి.ఎస్సీ (బాచిలర్ ఆఫ్ సైన్స్)',
    'బి.కామ్ (బాచిలర్ ఆఫ్ కామర్స్)',
    'బి.టెక్ (బాచిలర్ ఆఫ్ టెక్నాలజీ)',
    'బి.ఇ. (బాచిలర్ ఆఫ్ ఇంజనీరింగ్)',
    'ఎంబీబీఎస్ (బాచిలర్ ఆఫ్ మెడిసిన్, బాచిలర్ ఆఫ్ సర్జరీ)',
    'బి.డిఎస్ (బాచిలర్ ఆఫ్ డెంటల్ సర్జరీ)',
    'బి.ఆర్క్ (బాచిలర్ ఆఫ్ ఆర్కిటెక్చర్)',
    'ఎం.ఎ. (మాస్టర్ ఆఫ్ ఆర్ట్స్)',
    'ఎం.ఎస్సీ (మాస్టర్ ఆఫ్ సైన్స్)',
    'ఎం.కామ్ (మాస్టర్ ఆఫ్ కామర్స్)',
    'ఎం.టెక్ (మాస్టర్ ఆఫ్ టెక్నాలజీ)',
    'ఎం.ఇ. (మాస్టర్ ఆఫ్ ఇంజనీరింగ్)',
    'ఎంబీఏ (మాస్టర్ ఆఫ్ బిజినెస్ అడ్మినిస్ట్రేషన్)',
    'ఎం.డి. (డాక్టర్ ఆఫ్ మెడిసిన్)',
    'ఎం.ఎస్ (మాస్టర్ ఆఫ్ సర్జరీ)',
    'పిహెచ్.డి. (డాక్టర్ ఆఫ్ ఫిలాసఫీ)',
  ],
}
