import { SupportedLanguage } from '@/enum'
import { DropdownOption } from '@/types'

export const SPECIALIZATION_OPTIONS: DropdownOption = {
  [SupportedLanguage.English]: [
    'Computer Science',
    'Computer Engineering',
    'Mechanical Engineering',
    'Electrical Engineering',
    'Civil Engineering',
    'Biotechnology',
    'Economics',
    'Psychology',
    'English Literature',
    'History',
    'Political Science',
    'Commerce',
    'Physics',
    'Chemistry',
    'Mathematics',
    'Statistics',
    'Environmental Science',
    'Management',
    'Marketing',
    'Finance',
    'Accounting',
    'Law',
    'Medicine',
    'Dentistry',
    'Pharmacy',
    'Architecture',
    'Media Studies',
    'Business Management Studies (BMS)',
    'Mass Communication',
    'Journalism',
    'Film Studies',
    'Graphic Design',
    'Fashion Design',
    'Information Technology',
    'Hospitality Management',
    'Tourism Management',
    'Accounting and Finance',
    'Banking and Insurance',
    'Financial Markets',
    'Computer Applications',
    'Business Analytics',
    'E-Commerce',
    'Taxation',
    'Corporate Secretaryship',
    'Cost and Management Accounting',
    'Investment Management',
    'Financial Accounting',
    'Marketing Management',
    'Human Resource Management',
    'International Business',
    'Auditing',
    'Entrepreneurship',
  ],
  [SupportedLanguage.Hindi]: [
    'कंप्यूटर विज्ञान',
    'कंप्यूटर इंजीनियरिंग',
    'यांत्रिक इंजीनियरिंग',
    'विद्युत इंजीनियरिंग',
    'सिविल इंजीनियरिंग',
    'जैव प्रौद्योगिकी',
    'अर्थशास्त्र',
    'मनोविज्ञान',
    'अंग्रेजी साहित्य',
    'इतिहास',
    'राजनीति विज्ञान',
    'वाणिज्य',
    'भौतिकी',
    'रसायन विज्ञान',
    'गणित',
    'सांख्यिकी',
    'पर्यावरण विज्ञान',
    'प्रबंधन',
    'विपणन',
    'वित्त',
    'लेखांकन',
    'कानून',
    'चिकित्सा',
    'दंत चिकित्सा',
    'फार्मेसी',
    'वास्तुकला',
    'मीडिया अध्ययन',
    'व्यवसाय प्रबंधन अध्ययन (बीएमएस)',
    'जन संचार',
    'पत्रकारिता',
    'फिल्म अध्ययन',
    'ग्राफिक डिजाइन',
    'फैशन डिजाइन',
    'सूचना प्रौद्योगिकी',
    'अतिथि सत्कार प्रबंधन',
    'पर्यटन प्रबंधन',
    'लेखांकन और वित्त',
    'बैंकिंग और बीमा',
    'वित्तीय बाजार',
    'कंप्यूटर अनुप्रयोग',
    'व्यवसाय विश्लेषिकी',
    'ई-कॉमर्स',
    'कराधान',
    'कॉर्पोरेट सचिवालय',
    'लागत और प्रबंधन लेखांकन',
    'निवेश प्रबंधन',
    'वित्तीय लेखांकन',
    'विपणन प्रबंधन',
    'मानव संसाधन प्रबंधन',
    'अंतर्राष्ट्रीय व्यापार',
    'लेखा परीक्षा',
    'उद्यमिता',
  ],
  [SupportedLanguage.Marathi]: [
    'संगणक विज्ञान',
    'संगणक अभियांत्रिकी',
    'यांत्रिक अभियांत्रिकी',
    'विद्युत अभियांत्रिकी',
    'सिव्हिल अभियांत्रिकी',
    'जैवतंत्रज्ञान',
    'अर्थशास्त्र',
    'मानसशास्त्र',
    'इंग्रजी साहित्य',
    'इतिहास',
    'राजकीय शास्त्र',
    'वाणिज्य',
    'भौतिकशास्त्र',
    'रसायनशास्त्र',
    'गणित',
    'सांख्यिकी',
    'पर्यावरण विज्ञान',
    'व्यवस्थापन',
    'विपणन',
    'वित्त',
    'लेखांकन',
    'कायदा',
    'वैद्यकशास्त्र',
    'दंतवैद्यकशास्त्र',
    'फार्मसी',
    'वास्तुकला',
    'मीडिया अभ्यास',
    'व्यवसाय व्यवस्थापन अभ्यास (BMS)',
    'जनसंपर्क',
    'पत्रकारिता',
    'चित्रपट अभ्यास',
    'ग्राफिक डिझाइन',
    'फॅशन डिझाइन',
    'माहिती तंत्रज्ञान',
    'आतिथ्य व्यवस्थापन',
    'पर्यटन व्यवस्थापन',
    'लेखांकन आणि वित्त',
    'बँकिंग आणि विमा',
    'आर्थिक बाजार',
    'संगणक अनुप्रयोग',
    'व्यवसाय विश्लेषण',
    'ई-कॉमर्स',
    'कराधान',
    'कॉर्पोरेट सचिवालय',
    'खर्च आणि व्यवस्थापन लेखांकन',
    'गुंतवणूक व्यवस्थापन',
    'वित्तीय लेखांकन',
    'विपणन व्यवस्थापन',
    'मानव संसाधन व्यवस्थापन',
    'आंतरराष्ट्रीय व्यवसाय',
    'लेखापरीक्षण',
    'उद्योजकता',
  ],
  [SupportedLanguage.Gujarati]: [
    'કમ્પ્યુટર વિજ્ઞાન',
    'કમ્પ્યુટર ઈજનેરી',
    'મિકેનિકલ ઈજનેરી',
    'ઇલેક્ટ્રિકલ ઈજનેરી',
    'સિવિલ ઈજનેરી',
    'બાયોટેકનોલોજી',
    'અર્થશાસ્ત્ર',
    'મનોવિજ્ઞાન',
    'અંગ્રેજી સાહિત્ય',
    'ઇતિહાસ',
    'રાજકીય વિજ્ઞાન',
    'વાણિજ્ય',
    'ભૌતિક શાસ્ત્ર',
    'રસાયણ શાસ્ત્ર',
    'ગણિત',
    'સંખ્યાશાસ્ત્ર',
    'પર્યાવરણ વિજ્ઞાન',
    'મેનેજમેન્ટ',
    'માર્કેટિંગ',
    'ફાઇનાન્સ',
    'એકાઉન્ટિંગ',
    'કાયદો',
    'તબીબી',
    'દંત ચિકિત્સા',
    'ફાર્મસી',
    'સ્થાપત્ય',
    'મીડિયા અભ્યાસ',
    'બિઝનેસ મેનેજમેન્ટ સ્ટડીઝ (BMS)',
    'માસ કમ્યુનિકેશન',
    'પત્રકારિતા',
    'ફિલ્મ અભ્યાસ',
    'ગ્રાફિક ડિઝાઇન',
    'ફેશન ડિઝાઇન',
    'ઇન્ફોર્મેશન ટેકનોલોજી',
    'હોસ્પિટાલિટી મેનેજમેન્ટ',
    'ટૂરિઝમ મેનેજમેન્ટ',
    'એકાઉન્ટિંગ અને ફાઇનાન્સ',
    'બેંકિંગ અને વીમો',
    'ફાઇનાન્શિયલ માર્કેટ્સ',
    'કમ્પ્યુટર એપ્લિકેશન્સ',
    'બિઝનેસ એનાલિટિક્સ',
    'ઈ-કોમર્સ',
    'કરવેરો',
    'કોર્પોરેટ સેક્રેટરીશિપ',
    'કોસ્ટ અને મેનેજમેન્ટ એકાઉન્ટિંગ',
    'ઇન્વેસ્ટમેન્ટ મેનેજમેન્ટ',
    'ફાઇનાન્શિયલ એકાઉન્ટિંગ',
    'માર્કેટિંગ મેનેજમેન્ટ',
    'માનવ સંસાધન મેનેજમેન્ટ',
    'આંતરરાષ્ટ્રીય વ્યાપાર',
    'ઓડિટિંગ',
    'ઉદ્યોગપતિત્વ',
  ],
  [SupportedLanguage.Bengali]: [],
  [SupportedLanguage.Telugu]: [],
}
