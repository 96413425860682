import { SupportedLanguage, SupportedReligion } from '@/enum'
import { HeaderText } from '@/types'

export const HEADER_TEXT_OPTIONS: HeaderText[] = [
  {
    id: '001',
    text: '|| श्री गणेशाय नम: ||',
    religion: SupportedReligion.Hinduism,
    locale: [SupportedLanguage.Hindi, SupportedLanguage.English, SupportedLanguage.Marathi],
  },
  {
    id: '002',
    text: '|| ॐ श्रीकृष्णाय नमः ||',
    religion: SupportedReligion.Hinduism,
    locale: [SupportedLanguage.Hindi, SupportedLanguage.English, SupportedLanguage.Marathi],
  },
  {
    id: '015',
    text: '|| શ્રી ગણેશાય નમઃ ||',
    religion: SupportedReligion.Hinduism,
    locale: [SupportedLanguage.Gujarati],
  },
  {
    id: '0016',
    text: '|| শ্রী গণেশায় নমঃ ||',
    religion: SupportedReligion.Hinduism,
    locale: [SupportedLanguage.Bengali],
  },
  {
    id: '0017',
    text: '|| శ్రీ గణేశాయ నమ: ||',
    religion: SupportedReligion.Hinduism,
    locale: [SupportedLanguage.Telugu],
  },
  {
    id: '003',
    text: '|| नमो बुध्दाय ||',
    religion: SupportedReligion.Buddhism,
    locale: [SupportedLanguage.Hindi, SupportedLanguage.English, SupportedLanguage.Marathi],
  },
  {
    id: '004',
    text: '|| बुद्धं शरणं गच्छामि ||',
    religion: SupportedReligion.Buddhism,
    locale: [SupportedLanguage.Hindi, SupportedLanguage.English, SupportedLanguage.Marathi],
  },
  {
    id: '005',
    text: '|| जय भीम ||',
    religion: SupportedReligion.Buddhism,
    locale: [SupportedLanguage.Hindi, SupportedLanguage.English, SupportedLanguage.Marathi],
  },
  {
    id: '006',
    text: '|| नमो बुध्दाय ||  || नमो भिमाय ||',
    religion: SupportedReligion.Buddhism,
    locale: [SupportedLanguage.Hindi, SupportedLanguage.English, SupportedLanguage.Marathi],
  },
  {
    id: '006',
    text: '|| नमो बुध्दाय ||  || जय भीम ||',
    religion: SupportedReligion.Buddhism,
    locale: [SupportedLanguage.Hindi, SupportedLanguage.English, SupportedLanguage.Marathi],
  },
  {
    id: '007',
    text: '|| ॐ नमः सिद्धेभ्यः ||',
    religion: SupportedReligion.Jainism,
    locale: [SupportedLanguage.Hindi, SupportedLanguage.English, SupportedLanguage.Marathi],
  },
  {
    id: '008',
    text: '|| जय जिनेन्द्र ||',
    religion: SupportedReligion.Jainism,
    locale: [SupportedLanguage.Hindi, SupportedLanguage.English, SupportedLanguage.Marathi],
  },
  // {
  //   id: '009',
  //   text: '|| ਵਾਹਿਗੁਰੂ ਜੀ ਕਾ ਖਾਲਸਾ, ਵਾਹਿਗੁਰੂ ਜੀ ਕੀ ਫਤਹ ||',
  //   religion: SupportedReligion.Sikhism,
  //   locale: [SupportedLanguage.Hindi, SupportedLanguage.English, SupportedLanguage.Marathi],
  // },
  // {
  //   id: '010',
  //   text: 'بِسْمِ ٱللَّٰهِ ٱلرَّحْمَـٰنِ ٱلرَّحِيمِ',
  //   religion: SupportedReligion.Islam,
  //   locale: [SupportedLanguage.Hindi, SupportedLanguage.English, SupportedLanguage.Marathi],
  // },
  {
    id: '014',
    text: 'IN THE NAME OF ALLAH',
    religion: SupportedReligion.Islam,
    locale: [SupportedLanguage.Hindi, SupportedLanguage.English, SupportedLanguage.Marathi],
  },
  {
    id: '020',
    text: 'আল্লাহর নামে',
    religion: SupportedReligion.Islam,
    locale: [SupportedLanguage.Telugu],
  },
  {
    id: '018',
    text: 'అల్లాహ్ పేరుతో',
    religion: SupportedReligion.Islam,
    locale: [SupportedLanguage.Telugu],
  },
  {
    id: '019',
    text: 'అస్సలాము అలైకుం వ రహమతుల్లాహి వ బరకాతుహు',
    religion: SupportedReligion.Islam,
    locale: [SupportedLanguage.Telugu],
  },
  {
    id: '014',
    text: 'Assalamu Alaikum Wa Rahmatullahi Wa Barakatuh',
    religion: SupportedReligion.Islam,
    locale: [SupportedLanguage.Hindi, SupportedLanguage.English, SupportedLanguage.Marathi],
  },
  {
    id: '011',
    text: 'Praise the Lord',
    religion: SupportedReligion.Christianity,
    locale: [SupportedLanguage.Hindi, SupportedLanguage.English, SupportedLanguage.Marathi],
  },
  {
    id: '013',
    text: "With God's Grace",
    religion: SupportedReligion.Christianity,
    locale: [SupportedLanguage.Hindi, SupportedLanguage.English, SupportedLanguage.Marathi],
  },
  {
    id: '014',
    text: "In Christ's Love",
    religion: SupportedReligion.Christianity,
    locale: [SupportedLanguage.Hindi, SupportedLanguage.English, SupportedLanguage.Marathi],
  },
]
