import { SupportedLanguage } from '@/enum'
import { DropdownOption } from '@/types'

export const BLOODGROUP_OPTIONS: DropdownOption = {
  [SupportedLanguage.English]: ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'],
  [SupportedLanguage.Hindi]: ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'],
  [SupportedLanguage.Marathi]: ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'],
  [SupportedLanguage.Gujarati]: ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'],
  [SupportedLanguage.Bengali]: ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'],
  [SupportedLanguage.Telugu]: ['A+', 'A-', 'B+', 'B-', 'AB+', 'AB-', 'O+', 'O-'],
}
