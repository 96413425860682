import { SupportedLanguage } from '@/enum'
import { DropdownOption } from '@/types'

export const OCCUPATION_OPTIONS: DropdownOption = {
  [SupportedLanguage.English]: [
    'Accountant',
    'Actor',
    'Architect',
    'Artist',
    'Baker',
    'Banker',
    'Barber',
    'Bartender',
    'Businessman',
    'Chef',
    'Civil Engineer',
    'Dentist',
    'Doctor',
    'Electrician',
    'Engineer',
    'Farmer',
    'Firefighter',
    'Graphic Designer',
    'Journalist',
    'Lawyer',
    'Librarian',
    'Mechanic',
    'Musician',
    'Nurse',
    'Pharmacist',
    'Photographer',
    'Pilot',
    'Plumber',
    'Police Officer',
    'Professor',
    'Programmer',
    'Scientist',
    'Software Developer',
    'Teacher',
    'Veterinarian',
    'Writer',
    'Indian Administrative Service (IAS) Officer',
    'Indian Police Service (IPS) Officer',
    'Indian Foreign Service (IFS) Officer',
    'Indian Revenue Service (IRS) Officer',
    'Indian Forest Service (IFoS) Officer',
    'Public Sector Undertaking (PSU) Employee',
    'Defense Personnel (Army, Navy, Air Force)',
    'Scientist at Indian Space Research Organisation (ISRO)',
    'Scientist at Defence Research and Development Organisation (DRDO)',
    'Government School Teacher',
    'Professor at Government University',
    'Doctor in Government Hospital',
    'Engineer in Public Works Department (PWD)',
    'Bank Officer in Public Sector Bank',
    'Railway Officer',
    'Customs Officer',
    'Central Bureau of Investigation (CBI) Officer',
    'Income Tax Officer',
    'Public Sector Unit (PSU) Executive',
    'Municipal Corporation Employee',
    'Bank Teller',
    'Loan Officer',
    'Credit Analyst',
    'Branch Manager',
    'Financial Analyst',
    'Relationship Manager',
    'Investment Banker',
    'Mortgage Advisor',
    'Risk Manager',
    'Compliance Officer',
    'Treasury Analyst',
    'Personal Banker',
    'Bank Clerk',
    'Financial Planner',
    'Operations Manager',
    'Customer Service Representative',
    'Business Development Manager',
    'Wealth Manager',
    'Credit Risk Analyst',
    'Investment Advisor',
    'Blacksmith',
    'Carpenter',
    'Silversmith',
    'Tailor',
    'Weaver',
    'Potter',
    'Cobbler',
    'Jeweler',
    'Butcher',
    'Fisherman',
    'Painter',
    'Shoemaker',
    'Tanner',
    'Locksmith',
    'Stonemason',
    'Glassblower',
    'Cooper',
    'Goldsmith',
    'Housewife',
    'Chartered Accountant (CA)',
    'Human Resources (HR)',
    'Product Manager',
  ],
  [SupportedLanguage.Hindi]: [
    'लेखाकार',
    'अभिनेता',
    'वास्तुकार',
    'कलाकार',
    'बेकर',
    'बैंकर',
    'नाई',
    'बारटेंडर',
    'व्यवसायी',
    'शेफ',
    'सिविल इंजीनियर',
    'दंत चिकित्सक',
    'डॉक्टर',
    'इलेक्ट्रीशियन',
    'इंजीनियर',
    'किसान',
    'अग्निशामक',
    'ग्राफिक डिज़ाइनर',
    'पत्रकार',
    'वकील',
    'पुस्तकालय अध्यक्ष',
    'मैकेनिक',
    'संगीतकार',
    'नर्स',
    'फार्मासिस्ट',
    'फोटोग्राफर',
    'पायलट',
    'प्लम्बर',
    'पुलिस अधिकारी',
    'प्रोफेसर',
    'प्रोग्रामर',
    'वैज्ञानिक',
    'सॉफ्टवेयर डेवलपर',
    'शिक्षक',
    'पशु चिकित्सक',
    'लेखक',
    'भारतीय प्रशासनिक सेवा (IAS) अधिकारी',
    'भारतीय पुलिस सेवा (IPS) अधिकारी',
    'भारतीय विदेश सेवा (IFS) अधिकारी',
    'भारतीय राजस्व सेवा (IRS) अधिकारी',
    'भारतीय वन सेवा (IFoS) अधिकारी',
    'सार्वजनिक क्षेत्र के उपक्रम (PSU) कर्मचारी',
    'रक्षा कर्मी (सेना, नौसेना, वायु सेना)',
    'भारतीय अंतरिक्ष अनुसंधान संगठन (ISRO) में वैज्ञानिक',
    'रक्षा अनुसंधान और विकास संगठन (DRDO) में वैज्ञानिक',
    'सरकारी स्कूल शिक्षक',
    'सरकारी विश्वविद्यालय में प्रोफेसर',
    'सरकारी अस्पताल में डॉक्टर',
    'लोक निर्माण विभाग (PWD) में इंजीनियर',
    'सार्वजनिक क्षेत्र के बैंक में बैंक अधिकारी',
    'रेलवे अधिकारी',
    'सीमा शुल्क अधिकारी',
    'केंद्रीय जांच ब्यूरो (CBI) अधिकारी',
    'आयकर अधिकारी',
    'सार्वजनिक क्षेत्र के उपक्रम (PSU) कार्यकारी',
    'नगर निगम कर्मचारी',
    'बैंक टेलर',
    'ऋण अधिकारी',
    'क्रेडिट विश्लेषक',
    'शाखा प्रबंधक',
    'वित्तीय विश्लेषक',
    'संबंध प्रबंधक',
    'निवेश बैंकर',
    'बंधक सलाहकार',
    'जोखिम प्रबंधक',
    'अनुपालन अधिकारी',
    'ट्रेजरी विश्लेषक',
    'व्यक्तिगत बैंकर',
    'बैंक क्लर्क',
    'वित्तीय योजनाकार',
    'संचालन प्रबंधक',
    'ग्राहक सेवा प्रतिनिधि',
    'व्यवसाय विकास प्रबंधक',
    'धन प्रबंधक',
    'क्रेडिट जोखिम विश्लेषक',
    'निवेश सलाहकार',
    'लोहार',
    'बढ़ई',
    'रजतकार',
    'दर्जी',
    'बुनकर',
    'कुम्हार',
    'मोची',
    'जौहरी',
    'कसाई',
    'बनिया',
    'मछुआरा',
    'चित्रकार',
    'जूता बनाने वाला',
    'चमड़े का काम करने वाला',
    'ताला बनाने वाला',
    'राजमिस्त्री',
    'कांच उड़ाने वाला',
    'पीपे बनाने वाला',
    'सुनार',
    'गृहिणी',
    'चार्टर्ड एकाउंटेंट (सीए)',
    'मानव संसाधन (एचआर)',
    'उत्पाद प्रबंधक',
  ],
  [SupportedLanguage.Marathi]: [
    'लेखापाल',
    'अभिनेता',
    'वास्तुविशारद',
    'कलाकार',
    'बेकरी व्यावसायिक',
    'बँकर',
    'नाई',
    'बारटेंडर',
    'व्यवसायिक',
    'शेफ',
    'सिव्हिल इंजिनियर',
    'दंतवैद्य',
    'डॉक्टर',
    'विद्युत तज्ञ',
    'इंजिनियर',
    'शेतकरी',
    'अग्निशामक',
    'ग्राफिक डिझायनर',
    'पत्रकार',
    'वकील',
    'ग्रंथपाल',
    'मेकॅनिक',
    'संगीतकार',
    'नर्स',
    'फार्मासिस्ट',
    'छायाचित्रकार',
    'वैमानिक',
    'प्लंबर',
    'पोलीस अधिकारी',
    'प्राध्यापक',
    'प्रोग्रामर',
    'शास्त्रज्ञ',
    'सॉफ्टवेअर डेव्हलपर',
    'शिक्षक',
    'प्राणीचिकित्सक',
    'लेखक',
    'भारतीय प्रशासकीय सेवा (IAS) अधिकारी',
    'भारतीय पोलीस सेवा (IPS) अधिकारी',
    'भारतीय परराष्ट्र सेवा (IFS) अधिकारी',
    'भारतीय महसूल सेवा (IRS) अधिकारी',
    'भारतीय वन सेवा (IFoS) अधिकारी',
    'सार्वजनिक क्षेत्रातील उपक्रम (PSU) कर्मचारी',
    'संरक्षण कर्मचारी (सेना, नौदल, वायुसेना)',
    'भारतीय अंतराळ संशोधन संस्था (ISRO) येथे शास्त्रज्ञ',
    'संरक्षण संशोधन आणि विकास संस्था (DRDO) येथे शास्त्रज्ञ',
    'शासकीय शाळेतील शिक्षक',
    'शासकीय विद्यापीठातील प्राध्यापक',
    'शासकीय रुग्णालयातील डॉक्टर',
    'सार्वजनिक बांधकाम विभाग (PWD) मध्ये अभियंता',
    'सार्वजनिक क्षेत्रातील बँकेत बँक अधिकारी',
    'रेल्वे अधिकारी',
    'सीमा शुल्क अधिकारी',
    'केंद्रीय अन्वेषण विभाग (CBI) अधिकारी',
    'आयकर अधिकारी',
    'सार्वजनिक क्षेत्रातील उपक्रम (PSU) कार्यकारी',
    'महानगरपालिका कर्मचारी',
    'बँक टेलर',
    'कर्ज अधिकारी',
    'क्रेडिट विश्लेषक',
    'शाखा व्यवस्थापक',
    'आर्थिक विश्लेषक',
    'संबंध व्यवस्थापक',
    'गुंतवणूक बँकर',
    'गहाण सल्लागार',
    'जोखीम व्यवस्थापक',
    'अनुपालन अधिकारी',
    'खजिना विश्लेषक',
    'वैयक्तिक बँकर',
    'बँक लिपिक',
    'आर्थिक नियोजक',
    'ऑपरेशन्स मॅनेजर',
    'ग्राहक सेवा प्रतिनिधी',
    'व्यवसाय विकास व्यवस्थापक',
    'संपत्ती व्यवस्थापक',
    'क्रेडिट जोखीम विश्लेषक',
    'गुंतवणूक सल्लागार',
    'लोहार',
    'सुतार',
    'चांदीकार',
    'शिंपी',
    'विणकर',
    'कुंभार',
    'चांभार',
    'ज्वेलर',
    'कसाई',
    'मच्छीमार',
    'चित्रकार',
    'बूटमेकर',
    'टॅनर',
    'कुंभार',
    'शिल्पकार',
    'काच फुंकणारा',
    'कूपर',
    'सोनार',
    'गृहिणी',
    'चार्टर्ड अकाउंटंट (CA)',
    'मानव संसाधन (HR)',
    'उत्पादन व्यवस्थापक',
  ],
  [SupportedLanguage.Gujarati]: [
    'એકાઉન્ટન્ટ',
    'અભિનેતા',
    'આર્કિટેક્ટ',
    'કલાકાર',
    'બેકર',
    'બેંકર',
    'નાપિત',
    'બારટેન્ડર',
    'વેપારી',
    'શેફ',
    'સિવિલ ઈજનેર',
    'દંતચિકિત્સક',
    'ડોક્ટર',
    'ઇલેક્ટ્રિશિયન',
    'ઈજનેર',
    'ખેડૂત',
    'ફાયરફાઇટર',
    'ગ્રાફિક ડિઝાઇનર',
    'પત્રકાર',
    'વકીલ',
    'ગ્રંથપાલ',
    'મિકેનિક',
    'સંગીતકાર',
    'નર્સ',
    'ફાર્માસિસ્ટ',
    'ફોટોગ્રાફર',
    'પાયલોટ',
    'પ્લમ્બર',
    'પોલીસ અધિકારી',
    'પ્રોફેસર',
    'પ્રોગ્રામર',
    'વૈજ્ઞાનિક',
    'સોફ્ટવેર ડેવલપર',
    'શિક્ષક',
    'પશુચિકિત્સક',
    'લેખક',
    'ભારતીય પ્રશાસનિક સેવા (IAS) અધિકારી',
    'ભારતીય પોલીસ સેવા (IPS) અધિકારી',
    'ભારતીય વિદેશ સેવા (IFS) અધિકારી',
    'ભારતીય રાજસ્વ સેવા (IRS) અધિકારી',
    'ભારતીય વન સેવા (IFoS) અધિકારી',
    'પબ્લિક સેક્ટર અંડરટેકિંગ (PSU) કર્મચારી',
    'રક્ષા કર્મી (આર્મી, નેવી, એર ફોર્સ)',
    'ભારતીય અવકાશ સંશોધન સંગઠન (ISRO) માં વૈજ્ઞાનિક',
    'રક્ષા સંશોધન અને વિકાસ સંગઠન (DRDO) માં વૈજ્ઞાનિક',
    'સરકારી શાળાના શિક્ષક',
    'સરકારી યુનિવર્સિટીમાં પ્રોફેસર',
    'સરકારી હોસ્પિટલમાં ડોક્ટર',
    'પબ્લિક વર્ક્સ ડિપાર્ટમેન્ટ (PWD) માં ઈજનેર',
    'પબ્લિક સેક્ટર બેંકમાં બેંક ઓફિસર',
    'રેલવે ઓફિસર',
    'કસ્ટમ્સ ઓફિસર',
    'કેન્દ્રીય તપાસ બ્યુરો (CBI) ઓફિસર',
    'આવકવેરા ઓફિસર',
    'પબ્લિક સેક્ટર યુનિટ (PSU) એક્ઝિક્યુટિવ',
    'મ્યુનિસિપલ કોર્પોરેશન કર્મચારી',
    'બેંક ટેલર',
    'લોન ઓફિસર',
    'ક્રેડિટ એનાલિસ્ટ',
    'બ્રાંચ મેનેજર',
    'ફાઇનાન્શિયલ એનાલિસ્ટ',
    'રિલેશનશિપ મેનેજર',
    'ઇન્વેસ્ટમેન્ટ બેંકર',
    'મોર્ટગેજ એડવાઇઝર',
    'રિસ્ક મેનેજર',
    'કમ્પ્લાયન્સ ઓફિસર',
    'ટ્રેઝરી એનાલિસ્ટ',
    'પર્સનલ બેંકર',
    'બેંક ક્લાર્ક',
    'ફાઇનાન્શિયલ પ્લાનર',
    'ઓપરેશન્સ મેનેજર',
    'કસ્ટમર સર્વિસ પ્રતિનિધિ',
    'બિઝનેસ ડેવલપમેન્ટ મેનેજર',
    'વેલ્થ મેનેજર',
    'ક્રેડિટ રિસ્ક એનાલિસ્ટ',
    'ઇન્વેસ્ટમેન્ટ એડવાઇઝર',
    'લોહાર',
    'સુથાર',
    'ચાંદીકામ કારીગર',
    'દરજી',
    'વીણકર',
    'કુંભાર',
    'મોચી',
    'ઝવેરાત કારીગર',
    'કસાઈ',
    'માછીમાર',
    'ચિત્રકાર',
    'જૂતા બનાવનાર',
    'ચામડિયું કામ કરનાર',
    'તાળાં બનાવનાર',
    'પથ્થર કામ કરનાર',
    'કાચ ફૂંકનાર',
    'કૂપર',
    'સોની',
    'ગૃહિણી',
    'ચાર્ટર્ડ એકાઉન્ટન્ટ (CA)',
    'માનવ સંસાધનો (HR)',
    'પ્રોડક્ટ મેનેજર',
  ],
  [SupportedLanguage.Bengali]: [
    'অ্যাকাউন্টেন্ট',
    'অভিনেতা',
    'স্থপতি',
    'শিল্পী',
    'বেকার',
    'ব্যাংকার',
    'বাবার',
    'বারটেন্ডার',
    'ব্যবসায়ী',
    'শেফ',
    'সিভিল ইঞ্জিনিয়ার',
    'দন্ত চিকিৎসক',
    'ডাক্তার',
    'ইলেকট্রিশিয়ান',
    'ইঞ্জিনিয়ার',
    'কৃষক',
    'অগ্নিনির্বাপক',
    'গ্রাফিক ডিজাইনার',
    'সাংবাদিক',
    'আইনজীবী',
    'গ্রন্থাগারিক',
    'মেকানিক',
    'সঙ্গীতজ্ঞ',
    'নার্স',
    'ফার্মাসিস্ট',
    'ফটোগ্রাফার',
    'পাইলট',
    'প্লাম্বার',
    'পুলিশ কর্মকর্তা',
    'প্রফেসর',
    'প্রোগ্রামার',
    'বিজ্ঞানী',
    'সফটওয়্যার ডেভেলপার',
    'শিক্ষক',
    'পশুচিকিৎসক',
    'লেখক',
    'ভারতীয় প্রশাসনিক সেবা (IAS) কর্মকর্তা',
    'ভারতীয় পুলিশ সেবা (IPS) কর্মকর্তা',
    'ভারতীয় বৈদেশিক সেবা (IFS) কর্মকর্তা',
    'ভারতীয় রাজস্ব সেবা (IRS) কর্মকর্তা',
    'ভারতীয় বন সেবা (IFoS) কর্মকর্তা',
    'সরকারি খাত সংস্থা (PSU) কর্মচারী',
    'রক্ষী বাহিনীর কর্মী (সেনা, নৌ, বিমান বাহিনী)',
    'ভারতীয় মহাকাশ গবেষণা সংস্থা (ISRO) বিজ্ঞানী',
    'রক্ষা গবেষণা ও উন্নয়ন সংস্থা (DRDO) বিজ্ঞানী',
    'সরকারি স্কুল শিক্ষক',
    'সরকারি বিশ্ববিদ্যালয়ে প্রফেসর',
    'সরকারি হাসপাতালে ডাক্তার',
    'পাবলিক ওয়ার্কস ডিপার্টমেন্ট (PWD) ইঞ্জিনিয়ার',
    'পাবলিক সেক্টর ব্যাংকের ব্যাংক কর্মকর্তা',
    'রেলওয়ে কর্মকর্তা',
    'কাস্টমস কর্মকর্তা',
    'কেন্দ্রীয় তদন্ত ব্যুরো (CBI) কর্মকর্তা',
    'আয়কর কর্মকর্তা',
    'পাবলিক সেক্টর ইউনিট (PSU) এক্সিকিউটিভ',
    'মিউনিসিপাল কর্পোরেশন কর্মচারী',
    'ব্যাংক টেলার',
    'লোন অফিসার',
    'ক্রেডিট অ্যানালিস্ট',
    'ব্রাঞ্চ ম্যানেজার',
    'আর্থিক অ্যানালিস্ট',
    'রিলেশনশিপ ম্যানেজার',
    'ইনভেস্টমেন্ট ব্যাংকার',
    'মর্গেজ অ্যাডভাইজর',
    'রিস্ক ম্যানেজার',
    'কমপ্লায়েন্স অফিসার',
    'ট্রেজারি অ্যানালিস্ট',
    'পার্সোনাল ব্যাংকার',
    'ব্যাংক ক্লার্ক',
    'আর্থিক পরিকল্পনাকারী',
    'অপারেশন ম্যানেজার',
    'কাস্টমার সার্ভিস রিপ্রেজেন্টেটিভ',
    'ব্যবসায় উন্নয়ন ম্যানেজার',
    'ওয়েলথ ম্যানেজার',
    'ক্রেডিট রিস্ক অ্যানালিস্ট',
    'ইনভেস্টমেন্ট অ্যাডভাইজর',
    'লোহার কাজের কারিগর',
    'কাঠমিস্ত্রী',
    'রূপকার',
    'টেইলার',
    'তাঁতী',
    'মাটির মিস্ত্রী',
    'চামড়ার কাজের কারিগর',
    'গহনা তৈরিকার',
    'মাংস বিক্রেতা',
    'মাছ শিকারি',
    'চিত্রশিল্পী',
    'জুতো সেলাইকারী',
    'চামড়া পালিশকারী',
    'তালার মিস্ত্রী',
    'পাথরের কাজের মিস্ত্রী',
    'কাঁচের কাজের কারিগর',
    'কোপার',
    'সোনার কাজের মিস্ত্রী',
    'গৃহিনী',
    'চার্টার্ড অ্যাকাউন্টেন্ট (CA)',
    'মানবসম্পদ (HR)',
    'প্রোডাক্ট ম্যানেজার',
  ],
  [SupportedLanguage.Telugu]: [],
}
