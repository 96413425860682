import { SupportedLanguage } from '@/enum'
import { DropdownOption } from '@/types'

export const HINDU_CASTE_OPTIONS: DropdownOption = {
  [SupportedLanguage.English]: [
    'Ad Dharmi',
    'Adi Andhra',
    'Adi Dravida',
    'Adi Karnataka',
    'Agamudayar',
    'Agri',
    'Ahir',
    'Ahir - Ahir shimpi',
    'Ahirwar',
    'Ahom',
    'Ambalavasi',
    'Ambalavasi - Pisharody',
    'Ambalavasi - Poduval',
    'Amma Kodava',
    'Arakh Arakvanshiya',
    'Arekatica',
    'Arunthathiyar',
    'Arya Vysya',
    'Ayyaraka',
    'Badaga',
    'Badhai',
    'Bagdi',
    'Baghel Gaderiya',
    'Bahi',
    'Baidya',
    'Bairwa',
    'Baishnab',
    'Baishya',
    'Baishya - Saha',
    'Baishya Kapali',
    'Bajantri',
    'Balai',
    'Balija',
    'Balija - Balija Naidu',
    'Balija - Balija Reddy',
    'Balija - Ediga /Goud (Balija)',
    'Balija - Gajula / Kavarai',
    'Balija - Kapu',
    'Balija - Kavara',
    'Balija - Linga Balija',
    'Balija - Modikarlu',
    'Balija - Munnuru Kapu',
    'Balija - Musukama',
    'Balija - Namdarlu',
    'Balija - Pagadala',
    'Balija - Perika',
    'Balija - Setti Balija',
    'Balija - Surya Balija',
    'Balija - Telaga',
    'Balija - Thota',
    'Balija - Vada Balija',
    'Balija - Velama',
    'Balija - Waada Balija',
    'Balija Naidu',
    'Balija Naidu - Balija Naidu',
    'Balija Naidu - Balija Reddy',
    'Balija Naidu - Setti Balija',
    'Balija Naidu - Surya Balija',
    'Balija Naidu - Vada Balija',
    'Balija Naidu - Waada Balija',
    'Banayat Oriya',
    'Bania',
    'Bania - Agarwal',
    'Bania - Agrahari',
    'Bania - Asathi',
    'Bania - Ayodhyavasi',
    'Bania - Baniya Kumuti',
    'Bania - Barnwals',
    'Bania - Bisa Agarwal',
    'Bania - Chaturth',
    'Bania - Choudharys',
    'Bania - Dosar / Dusra',
    'Bania - Gahoi',
    'Bania - Gandha Vanika',
    'Bania - Gulahre',
    'Bania - Jaiswal',
    'Bania - Kalwar',
    'Bania - Kandu / Kanu',
    'Bania - Kanojia Kanu',
    'Bania - Kanykubj Bania',
    'Bania - Kasaundhan',
    'Bania - Keshris / Kesarwani',
    'Bania - Khandelwal',
    'Bania - Komti Arya Vaishya',
    'Bania - Lad',
    'Bania - Madhesiya / Kawa / Halwai',
    'Bania - Mahajan',
    'Bania - Maheshwari/Meshri',
    'Bania - Mahor',
    'Bania - Mahuri',
    'Bania - Marwari',
    'Bania - Modh Ghanchi',
    'Bania - Modi',
    'Bania - Nema',
    'Bania - Oswal',
    'Bania - Padmavati Porwal',
    'Bania - Patwa',
    'Bania - Porwal / Porwar',
    'Bania - Rastogi',
    'Bania - Rathi',
    'Bania - Rauniars',
    'Bania - Rauniyar',
    'Bania - Shaw / Sahu / Teli',
    'Bania - Sinduriya',
    'Bania - Sudi / Suri / Sundhi / Shaundik',
    'Bania - Ummar / Umre / Bagaria',
    'Bania - Vaishnav',
    'Bania - Vani/Vaishya',
    'Bania - Varshneys',
    'Bania - Vijayvargia',
    'Banik',
    'Banjara',
    'Banjara - Lambani',
    'Barai',
    'Bari',
    'Baria',
    'Beldar',
    'Besta',
    'Bhajantri',
    'Bhatia',
    'Bhatraju',
    'Bhavsar',
    'Bhil',
    'Bhovi / Bhoi',
    'Bhoyar',
    'Bhulia / Meher',
    'Billava',
    'Bishnoi / Vishnoi',
    'Bondili',
    'Boyer',
    'Brahmakshatriya',
    'Brahmbatt',
    'Brahmin',
    'Brahmin - 6000 Niyogi',
    'Brahmin - Anavil',
    'Brahmin - Andhra',
    'Brahmin - Audichya',
    'Brahmin - Audichyasahastra',
    'Brahmin - Bajkhedwal',
    'Brahmin - Bardai',
    'Brahmin - Barendra',
    'Brahmin - Bengali',
    'Brahmin - Bhargava',
    'Brahmin - Bhatt',
    'Brahmin - Bhumihar',
    'Brahmin - Brahacharanam',
    'Brahmin - BrahmBhatt',
    'Brahmin - Brajastha Maithil',
    'Brahmin - Chittpavan Kokanastha',
    'Brahmin - Dadhich',
    'Brahmin - Daivadnya',
    'Brahmin - Danua',
    'Brahmin - Deshastha',
    'Brahmin - Devrukhe',
    'Brahmin - Dhiman',
    'Brahmin - Dravida',
    'Brahmin - Dunua',
    'Brahmin - Embrandiri',
    'Brahmin - Garhwali',
    'Brahmin - Gaud Saraswat (GSB)',
    'Brahmin - Gaur',
    'Brahmin - Gautam',
    'Brahmin - Goswami',
    'Brahmin - Gujar Gaur',
    'Brahmin - Gujrati',
    'Brahmin - Gurukkal',
    'Brahmin - Halua',
    'Brahmin - Havyaka',
    'Brahmin - Hoysala',
    'Brahmin - Iyengar',
    'Brahmin - Iyer',
    'Brahmin - Jangid',
    'Brahmin - Jangra',
    'Brahmin - Jhadua',
    'Brahmin - Jhijhotiya',
    'Brahmin - Jogi',
    'Brahmin - Jyotish',
    'Brahmin - Kannada',
    'Brahmin - Kanyakubj',
    'Brahmin - Karhade',
    'Brahmin - Karnataka',
    'Brahmin - Kashmiri Pandit',
    'Brahmin - Khadayat',
    'Brahmin - Khandelwal',
    'Brahmin - Khedaval',
    'Brahmin - Koknastha',
    'Brahmin - Kota',
    'Brahmin - Kulin',
    'Brahmin - Kumaoni',
    'Brahmin - Madhwa',
    'Brahmin - Maharastra',
    'Brahmin - Maithil',
    'Brahmin - Malviya',
    'Brahmin - Marwari',
    'Brahmin - Mevada',
    'Brahmin - Modh',
    'Brahmin - Mohapatra Mahapatra',
    'Brahmin - Mohyal',
    'Brahmin - Nagar',
    'Brahmin - Namboodiri',
    'Brahmin - Narmadiya',
    'Brahmin - Nayi / Nai',
    'Brahmin - Paliwal',
    'Brahmin - Panda',
    'Brahmin - Pandit',
    'Brahmin - Panicker',
    'Brahmin - Pareek',
    'Brahmin - Pushkarna',
    'Brahmin - Rajapuri Saraswat',
    'Brahmin - Rajasthani',
    'Brahmin - Rajgor',
    'Brahmin - Rarhi',
    'Brahmin - Rigvedi',
    'Brahmin - Rudraj',
    'Brahmin - Sachora',
    'Brahmin - Sakaldwipi',
    'Brahmin - Sanadya',
    'Brahmin - Sanchihar',
    'Brahmin - Sanketi',
    'Brahmin - Saraswat',
    'Brahmin - Sarotri',
    'Brahmin - Sarua',
    'Brahmin - Saryuparin',
    'Brahmin - Shivalli',
    'Brahmin - Shrimali',
    'Brahmin - Sikhwal',
    'Brahmin - Smartha',
    'Brahmin - Sri Vishnava',
    'Brahmin - Stanika',
    'Brahmin - Tapodhan',
    'Brahmin - Tyagi',
    'Brahmin - Utkal',
    'Brahmin - Vaidiki',
    'Brahmin - Vaikhanasa',
    'Brahmin - Vaikhawas',
    'Brahmin - Vaishnav',
    'Brahmin - Valam',
    'Brahmin - Velanadu',
    'Brahmin - Viswa',
    'Brahmin - Vyas',
    'Brahmin - Yajurvedi',
    'Brahmin - Zalora',
    'Brahmo',
    'Buddar',
    'Bunt Shetty',
    'Chalawadi / Holeya',
    'Chamar',
    'Chambhar',
    'Chandravanshi Kahar',
    'Charan',
    'Chasa',
    'Chattada Sri Vaishnava',
    'Chaudary',
    'Chaudary - Ghrit',
    'Chaurasia',
    'Chennadasar',
    'Cheramar',
    'Chettiar',
    'Chettiar - 24 Manai Telugu Chettiar',
    'Chettiar - 24 Manai Telugu Chettiar 16 Veedu',
    'Chettiar - 24 Manai Telugu Chettiar 8 Veedu',
    'Chettiar - Achirapakkam Chettiar',
    'Chettiar - Agaram Vellan Chettiar',
    'Chettiar - Arya Vysya',
    'Chettiar - Ayira Vysya',
    'Chettiar - Beri Chettiar',
    'Chettiar - Devanga Chettiar',
    'Chettiar - Elur Chetty',
    'Chettiar - Gandla / Ganiga',
    'Chettiar - Kasukara',
    'Chettiar - Kongu Chettiar',
    'Chettiar - Kuruhini Chetty',
    'Chettiar - Manjapudur Chettiar',
    'Chettiar - Nattukottai Chettiar',
    'Chettiar - Padma Saliar',
    'Chettiar - Pannirandam Chettiar',
    'Chettiar - Parvatha Rajakulam',
    'Chettiar - Pattinavar',
    'Chettiar - Pattusali',
    'Chettiar - Sadhu Chetty',
    'Chettiar - Saiva Vellan Chettiar',
    'Chettiar - Senai Thalaivar',
    'Chettiar - Sozhia Chettiar',
    'Chettiar - Telugupatti',
    'Chettiar - Vadambar',
    'Chettiar - Vaniya Chettiar',
    'Chettiar - Vellan Chettiar',
    'Chhetri',
    'Chippolu Mera',
    'CKP',
    'Coorgi',
    'Coorgi - Kodava',
    'Dasapalanjika Kannada Saineegar',
    'Devadigas',
    'Devang Koshthi',
    'Devang Koshthi - Devanga',
    'Devang Koshthi - Devanga Chettiar',
    'Devanga',
    'Devendra Kula Vellalar',
    'Devipujak (Vaghri)',
    'Dewar / Dhibara',
    'Dhanak',
    'Dhangar',
    'Dhanuk',
    'Dheevara',
    'Dhiman',
    'Dhoba',
    'Dhobi',
    'Dhobi - Kanaujia',
    'Dhor / Dhoar',
    'Dommala',
    'Dumal',
    'Dusadh',
    'Edigas',
    'Ezhava',
    'Ezhava - Ezhava panicker',
    'Ezhava - Ezhava Thandan',
    'Ezhava - Kavuthiya',
    'Ezhava - Thiyya',
    'Ezhuthachan',
    'Gabit',
    'Ganda',
    'Gangai / Ganesh',
    'Ganiga',
    'Ganiga - Shiva Jyothipana',
    'Garhwali',
    'Gatti',
    'Gavali',
    'Gavandi',
    'Gavara',
    'Ghasi',
    'Ghisadi',
    'Ghumar',
    'Goala',
    'Goan',
    'Gond Gondi Raj Gond',
    'Gondhali',
    'Gopal',
    'Goud',
    'Gounder',
    'Gounder - Kongu Vellala Gounder',
    'Gounder - Nattu Gounder',
    'Gounder - Urali Gounder',
    'Gounder - Vanniya Kula Kshatriyar',
    'Gounder - Vettuva Gounder',
    'Gowda',
    'Gowda - Arebashe',
    'Gowda - Bunt',
    'Gowda - Das',
    'Gowda - Gangadikar',
    'Gowda - Gowda-Kuruba Gowda',
    'Gowda - Hallikar',
    'Gowda - Hosadevaru',
    'Gowda - Kunchitaga',
    'Gowda - Morasu',
    'Gowda - Musuku',
    'Gowda - Namadari',
    'Gowda - Okkaliga',
    'Gowda - Reddy',
    'Gowda - Sarpa',
    'Gowda - Uttamakula',
    'Gramani',
    'Gudia',
    'Gujjar',
    'Gujjar - Dode',
    'Gujjar - Leva',
    'Guptan',
    'Gurav',
    'Halba Koshti',
    'Hegde',
    'Helava',
    'Holar',
    'Jaalari',
    'Jaiswal',
    'Jandra',
    'Jangam',
    'Jat',
    'Jat - Agharia',
    'Jat - Agoh',
    'Jat - Agra',
    'Jat - Ahlawat',
    'Jat - Alwal',
    'Jat - Antil / Antal',
    'Jat - Arya',
    'Jat - Badhwar',
    'Jat - Bagaria',
    'Jat - Bagri',
    'Jat - Bains',
    'Jat - Balhara',
    'Jat - Balyan',
    'Jat - Bana',
    'Jat - Barach',
    'Jat - Bargoti',
    'Jat - Beniwal',
    'Jat - Bhal',
    'Jat - Bhambu',
    'Jat - Bhodu',
    'Jat - Bomal',
    'Jat - Budania',
    'Jat - Bugaliya',
    'Jat - Burdak',
    'Jat - Chahal',
    'Jat - Chaudhry',
    'Jat - Chauhan',
    'Jat - Chhikara / Chikara',
    'Jat - Chillar',
    'Jat - Dabas',
    'Jat - Dagar',
    'Jat - Dagua',
    'Jat - Dahiya',
    'Jat - Dalal',
    'Jat - Dangi',
    'Jat - Dara',
    'Jat - Deshwal',
    'Jat - Dhaka',
    'Jat - Dhama',
    'Jat - Dhanchak',
    'Jat - Dhanda',
    'Jat - Dhankhar',
    'Jat - Dhillon',
    'Jat - Dhomi',
    'Jat - Dhoot',
    'Jat - Dhoriwal',
    'Jat - Dhull',
    'Jat - Dollya',
    'Jat - Dudi',
    'Jat - Duhan',
    'Jat - Gahlot',
    'Jat - Garhwal',
    'Jat - Gehlawat',
    'Jat - Ghangas',
    'Jat - Gill',
    'Jat - Godara',
    'Jat - Grewal',
    'Jat - Gulia',
    'Jat - Heer',
    'Jat - Hooda',
    'Jat - Jaglon',
    'Jat - Jakhar',
    'Jat - Jam',
    'Jat - Jaswal',
    'Jat - Jatasra',
    'Jat - Jewlia',
    'Jat - Jutrana',
    'Jat - Kadian',
    'Jat - Kahlon',
    'Jat - Kajala',
    'Jat - Kakran',
    'Jat - Kalen',
    'Jat - Kaliramna',
    'Jat - Kalkhanse',
    'Jat - Karwasra',
    'Jat - Kaswan',
    'Jat - Kataria',
    'Jat - Khakar',
    'Jat - Khallia',
    'Jat - Kharb',
    'Jat - Khatkar',
    'Jat - Khatri',
    'Jat - Kherwa',
    'Jat - Khichad',
    'Jat - Kothari',
    'Jat - Kuhar',
    'Jat - Kulhari',
    'Jat - Kundu',
    'Jat - Lakhlan',
    'Jat - Lakra',
    'Jat - Lamba',
    'Jat - Lamoria',
    'Jat - Lather',
    'Jat - Lathwal',
    'Jat - Latiyan',
    'Jat - Laur',
    'Jat - Lehga',
    'Jat - Maan',
    'Jat - Mahan',
    'Jat - Malhan',
    'Jat - Malik',
    'Jat - Mandhan',
    'Jat - Mangat',
    'Jat - Mann Rai',
    'Jat - Meel',
    'Jat - Mehria',
    'Jat - Mhla',
    'Jat - Mohar',
    'Jat - Moond',
    'Jat - Mor / More',
    'Jat - Nain',
    'Jat - Nairwal',
    'Jat - Nandal',
    'Jat - Nara',
    'Jat - Natt / Nat',
    'Jat - Nauhr',
    'Jat - Nehra',
    'Jat - Nitharwal',
    'Jat - Ola',
    'Jat - Pachehra',
    'Jat - Palsania',
    'Jat - Panghal',
    'Jat - Panwar',
    'Jat - Parihar',
    'Jat - Pattor',
    'Jat - Pawar',
    'Jat - Phalaswal',
    'Jat - Phogat',
    'Jat - Pilania',
    'Jat - Pooni',
    'Jat - Punia',
    'Jat - Rahan',
    'Jat - Rajaura',
    'Jat - Rana',
    'Jat - Rangi',
    'Jat - Ranwa',
    'Jat - Rao',
    'Jat - Rathi',
    'Jat - Rawal',
    'Jat - Redhu',
    'Jat - Repswal',
    'Jat - Saharan',
    'Jat - Sandhi',
    'Jat - Sangawan',
    'Jat - Sansanwal',
    'Jat - Saran',
    'Jat - Saroha',
    'Jat - Sarot',
    'Jat - Sehrawat',
    'Jat - Sheoran',
    'Jat - Shokeen',
    'Jat - Sidhu',
    'Jat - Sikarwar',
    'Jat - Sindhu',
    'Jat - Singhal',
    'Jat - Sinsinwar',
    'Jat - Sirohi',
    'Jat - Siwach',
    'Jat - Solanki',
    'Jat - Suhag',
    'Jat - Sunda',
    'Jat - Takhar',
    'Jat - Tanar',
    'Jat - Tanwar',
    'Jat - Tevatia',
    'Jat - Thakaran',
    'Jat - Thenua',
    'Jat - Thori',
    'Jat - Tokas',
    'Jat - Tomar',
    'Jatav',
    'Jetty Malla',
    'Jingar',
    'Julaha',
    'Kachara',
    'Kahar',
    'Kaibarta',
    'Kaikaala',
    'Kakkalan',
    'Kalal',
    'Kalanji',
    'Kalar',
    'Kalinga',
    'Kalinga Vysya',
    'Kalita',
    'Kalwar',
    'Kamboj',
    'Kamma',
    'Kammala',
    'Kanakaan Padonna',
    'Kanakkan Padanna',
    'Kandara',
    'Kaniyan',
    'Kansari',
    'Kansyakaar',
    'Kapol',
    'Kapu',
    'Kapu - Balija/Balija Naidu',
    'Kapu - Ediga /Goud (Balija)',
    'Kapu - Gajula / Kavarai',
    'Kapu - Kapu All',
    'Kapu - Kapu Munnuru',
    'Kapu - Kapu Naidu',
    'Kapu - kurupu / Kapu',
    'Kapu - Ontari',
    'Kapu - Perika',
    'Kapu - Reddy',
    'Kapu - Setty Balija',
    'Kapu - Surya Balija',
    'Kapu - Telaga',
    'Kapu - Turupu Kapu',
    'Kapu - Velama',
    'Kapu Munnuru',
    'Karakala Bhakthula',
    'Karana',
    'Karmakar',
    'Karuneegar',
    'Kasar',
    'Kashyap',
    'Kashyap - Nishad',
    'Katiya',
    'Kayastha',
    'Kayastha - Ambastha',
    'Kayastha - Ambastha Kayastha',
    'Kayastha - Asthana',
    'Kayastha - Barujibi',
    'Kayastha - Basu',
    'Kayastha - Bengali Kayastha',
    'Kayastha - Bhatnagar',
    'Kayastha - Bose',
    'Kayastha - Chanda',
    'Kayastha - Dass',
    'Kayastha - Dey',
    'Kayastha - Dhar',
    'Kayastha - Dutta',
    'Kayastha - Ghosh',
    'Kayastha - Gour',
    'Kayastha - Guha',
    'Kayastha - Johri',
    'Kayastha - Karna',
    'Kayastha - Kars',
    'Kayastha - Kulin',
    'Kayastha - Kulshreshtha',
    'Kayastha - Mathur',
    'Kayastha - Mitra',
    'Kayastha - Nag',
    'Kayastha - Nandi',
    'Kayastha - Nigam',
    'Kayastha - Palit',
    'Kayastha - Paul',
    'Kayastha - Rakshit',
    'Kayastha - Rarhi',
    'Kayastha - Roy',
    'Kayastha - Saxena',
    'Kayastha - Sen',
    'Kayastha - Sil',
    'Kayastha - Sinha',
    'Kayastha - Srivastava',
    'Khandayat',
    'Khandayat - Kalinja',
    'Kharvi',
    'Kharwar',
    'Khatik',
    'Khatri',
    'Khatri - Anand',
    'Khatri - Arora',
    'Khatri - Bagga',
    'Khatri - Bahl',
    'Khatri - Batra',
    'Khatri - Bedi',
    'Khatri - Behal',
    'Khatri - Behl',
    'Khatri - Beri',
    'Khatri - Bhalla',
    'Khatri - Bhandari',
    'Khatri - Bhasin',
    'Khatri - Bhatti',
    'Khatri - Chaddha',
    'Khatri - Chadha',
    'Khatri - Chandok',
    'Khatri - Chaudhary',
    'Khatri - Chhabra',
    'Khatri - Chopra',
    'Khatri - Choudhuri',
    'Khatri - Dang',
    'Khatri - Dhawan',
    'Khatri - Dhir',
    'Khatri - Duggal',
    'Khatri - Ghai',
    'Khatri - Handa',
    'Khatri - Jaggi',
    'Khatri - Jairath',
    'Khatri - Jerath',
    'Khatri - Jham',
    'Khatri - Kakkar',
    'Khatri - Kapur / Kapoor',
    'Khatri - Kehar',
    'Khatri - Khanna',
    'Khatri - Khosla',
    'Khatri - Khukrain',
    'Khatri - Khullar',
    'Khatri - Kochar',
    'Khatri - Kohli',
    'Khatri - Lamba',
    'Khatri - Mahendru',
    'Khatri - Malhotra',
    'Khatri - Marwah',
    'Khatri - Mehra',
    'Khatri - Mehta',
    'Khatri - Nagrath',
    'Khatri - Nanda',
    'Khatri - Nayyar',
    'Khatri - Oberoi',
    'Khatri - Ohri',
    'Khatri - Passi',
    'Khatri - Puri',
    'Khatri - Sabharwal',
    'Khatri - Sahni',
    'Khatri - Sareen',
    'Khatri - Sarin',
    'Khatri - Sawhney',
    'Khatri - Sehgal',
    'Khatri - Sekhri',
    'Khatri - Seth',
    'Khatri - Sethi',
    'Khatri - Sobto',
    'Khatri - Sodhi',
    'Khatri - Sondhi',
    'Khatri - Soni',
    'Khatri - Sood',
    'Khatri - Suri',
    'Khatri - Talwar',
    'Khatri - Tandon',
    'Khatri - Thapar',
    'Khatri - Tuli',
    'Khatri - Uppal',
    'Khatri - Vadhera',
    'Khatri - Verma',
    'Khatri - Vij',
    'Khatri - Vohra',
    'Khatri - Wadhawan',
    'Khatri - Wahi',
    'Khatri - Walia',
    'Koeri / Koiri',
    'Koli',
    'Koli - Koli Mahadev',
    'Koli - Koli Patel',
    'Koli - Mangela',
    'Koli Mahadev',
    'Kondara',
    'Kongu Vellala Gounder',
    'Kongu Vellala Gounder - Aadai',
    'Kongu Vellala Gounder - Aadhi',
    'Kongu Vellala Gounder - Aanthai',
    'Kongu Vellala Gounder - Aavan',
    'Kongu Vellala Gounder - Alagan',
    'Kongu Vellala Gounder - Andai',
    'Kongu Vellala Gounder - Andhuvan',
    'Kongu Vellala Gounder - Cheran',
    'Kongu Vellala Gounder - Devendran',
    'Kongu Vellala Gounder - Eenjan',
    'Kongu Vellala Gounder - Ennai',
    'Kongu Vellala Gounder - Kaadai',
    'Kongu Vellala Gounder - Kaari',
    'Kongu Vellala Gounder - Kanakkan',
    'Kongu Vellala Gounder - Kannan',
    'Kongu Vellala Gounder - Kannandhai',
    'Kongu Vellala Gounder - Keeran',
    'Kongu Vellala Gounder - Koorai',
    'Kongu Vellala Gounder - Koventhar',
    'Kongu Vellala Gounder - Kuzhlaayan',
    'Kongu Vellala Gounder - Maadai',
    'Kongu Vellala Gounder - Maniyan',
    'Kongu Vellala Gounder - Medhi',
    'Kongu Vellala Gounder - Muthan',
    'Kongu Vellala Gounder - Muzhlukkadhan',
    'Kongu Vellala Gounder - Nattu Gounder',
    'Kongu Vellala Gounder - Odhaalar',
    'Kongu Vellala Gounder - Paandian',
    'Kongu Vellala Gounder - Padaithalaiyan',
    'Kongu Vellala Gounder - Panaiyan',
    'Kongu Vellala Gounder - Panangadai',
    'Kongu Vellala Gounder - Pannai',
    'Kongu Vellala Gounder - Pannan',
    'Kongu Vellala Gounder - Pavalan',
    'Kongu Vellala Gounder - Payiran',
    'Kongu Vellala Gounder - Periyan',
    'Kongu Vellala Gounder - Perizhanthan',
    'Kongu Vellala Gounder - Perunkudi',
    'Kongu Vellala Gounder - Pillan',
    'Kongu Vellala Gounder - Podiyan',
    'Kongu Vellala Gounder - Ponnan',
    'Kongu Vellala Gounder - Poochadhai Poodhiyan',
    'Kongu Vellala Gounder - Poosan',
    'Kongu Vellala Gounder - Sathandhai',
    'Kongu Vellala Gounder - Sedan',
    'Kongu Vellala Gounder - Sellan',
    'Kongu Vellala Gounder - Sempoothan',
    'Kongu Vellala Gounder - Sengannan',
    'Kongu Vellala Gounder - Sengunni',
    'Kongu Vellala Gounder - Seralan',
    'Kongu Vellala Gounder - Sevadi',
    'Kongu Vellala Gounder - Thodai',
    'Kongu Vellala Gounder - Thooran',
    'Kongu Vellala Gounder - Vannakkan',
    'Kongu Vellala Gounder - Veliyan',
    'Kongu Vellala Gounder - Vellamban',
    'Kongu Vellala Gounder - Venduvan',
    'Kongu Vellala Gounder - Viliyan',
    'Kongu Vellala Gounder - Villi',
    'Konkani',
    'Koracha',
    'Korama',
    'Kori',
    'Kori / Koli',
    'Korvi',
    'Koshti',
    'Krishnavaka',
    'Kshatriya',
    'Kshatriya - Agnikula Kshatriya',
    'Kshatriya - Aguri Ugra Kshatriya',
    'Kshatriya - Amethia',
    'Kshatriya - Bachhil',
    'Kshatriya - Bagel',
    'Kshatriya - Baghela / Veghela',
    'Kshatriya - Banafar',
    'Kshatriya - Bhadoria',
    'Kshatriya - Bhandari',
    'Kshatriya - Bhardwaj',
    'Kshatriya - Bhatraju',
    'Kshatriya - Bhavasar Kshatriya',
    'Kshatriya - Bundela',
    'Kshatriya - Chopra',
    'Kshatriya - Chudasa',
    'Kshatriya - Dangi',
    'Kshatriya - Dhawan',
    'Kshatriya - Dixit',
    'Kshatriya - Gaherwar',
    'Kshatriya - Gargvansi',
    'Kshatriya - Gaur',
    'Kshatriya - Hada',
    'Kshatriya - Haihaivanshi',
    'Kshatriya - Jaiswar',
    'Kshatriya - Janwar',
    'Kshatriya - Kandera',
    'Kshatriya - Kapur',
    'Kshatriya - Katiyar',
    'Kshatriya - Khandayat',
    'Kshatriya - Khanna',
    'Kshatriya - Khare',
    'Kshatriya - Kshatriya Raju',
    'Kshatriya - Kshatriya Raju Chandravamsam',
    'Kshatriya - Kshatriya Raju Suryavamsam',
    'Kshatriya - Kumawat',
    'Kshatriya - Kurmi',
    'Kshatriya - Mehra',
    'Kshatriya - Nagvanshi',
    'Kshatriya - Negi',
    'Kshatriya - Niari',
    'Kshatriya - Nikhumbh',
    'Kshatriya - Paliwal',
    'Kshatriya - Pawar',
    'Kshatriya - Perika Puragiri Kshatriya',
    'Kshatriya - Pundir',
    'Kshatriya - Raikwar',
    'Kshatriya - Rajkumar',
    'Kshatriya - Rajwar',
    'Kshatriya - Rama Kshatriya',
    'Kshatriya - Rathor',
    'Kshatriya - Rawal',
    'Kshatriya - Rawat',
    'Kshatriya - Sahni',
    'Kshatriya - Saithwar- Mall',
    'Kshatriya - Sami',
    'Kshatriya - Sengar',
    'Kshatriya - Seth',
    'Kshatriya - Shrinet',
    'Kshatriya - Singh',
    'Kshatriya - Sisodiya',
    'Kshatriya - Soma Vamsha Arya Kshatriya',
    'Kshatriya - Somavanshi Sahasrarjun Kshatriya',
    'Kshatriya - Somvanshi',
    'Kshatriya - Tandon',
    'Kshatriya - Tanwar',
    'Kshatriya - Thogata Veera Kshatriya',
    'Kshatriya - Tomar / Tanwar',
    'Kshatriya - Tong Kshatriya',
    'Kshatriya - Vahi',
    'Kshatriya - Vohra',
    'Kshatriya - Wadhwa',
    'Kshatriya Agnikula',
    'Kudumbi',
    'Kulalar',
    'Kulita',
    'Kumaoni',
    'Kumawat',
    'Kumbhakar',
    'Kumhar / Kumbhar',
    'Kummari',
    'Kunbi',
    'Kunbi - Ghatode',
    'Kunbi - Kunbi- Dhanoje',
    'Kunbi - Kunbi- Khaire',
    'Kunbi - Kunbi- Khedule',
    'Kunbi - Kunbi- Lonari',
    'Kunbi - Kunbi- Maratha',
    'Kunbi - Kunbi- Tirale',
    'Kunbi - Zade',
    'Kurava',
    'Kuravan / Kuravar',
    'Kurmi',
    'Kurmi - Awadhiya',
    'Kurmi - Baghel',
    'Kurmi - Chandel',
    'Kurmi - Chandra',
    'Kurmi - Chandrakar',
    'Kurmi - Chandrawanshi',
    'Kurmi - Chaudhary',
    'Kurmi - Chaudhury',
    'Kurmi - Deshmukh',
    'Kurmi - Gangwar',
    'Kurmi - Ghamaila',
    'Kurmi - Jaiswar',
    'Kurmi - Kashyap',
    'Kurmi - Katiyar',
    'Kurmi - Kochyasa',
    'Kurmi - Kumar',
    'Kurmi - Kushwaha (Koiri)',
    'Kurmi - Mahata',
    'Kurmi - Mahato',
    'Kurmi - Mahto',
    'Kurmi - Parganiha',
    'Kurmi - Patel',
    'Kurmi - Patidar',
    'Kurmi - Prasad',
    'Kurmi - Rai',
    'Kurmi - Sachan',
    'Kurmi - Singh',
    'Kurmi - Verma',
    'Kurmi Kshatriya',
    'Kurmi Kshatriya - Awadhya',
    'Kurmi Kshatriya - Baghel',
    'Kurmi Kshatriya - Chandel',
    'Kurmi Kshatriya - Chandra',
    'Kurmi Kshatriya - Chandrakar',
    'Kurmi Kshatriya - Chandrawanshi',
    'Kurmi Kshatriya - Chaudhury',
    'Kurmi Kshatriya - Deshmukh',
    'Kurmi Kshatriya - Gangwar',
    'Kurmi Kshatriya - Ghamaila',
    'Kurmi Kshatriya - Jaiswar',
    'Kurmi Kshatriya - Kashyap',
    'Kurmi Kshatriya - Katiyar',
    'Kurmi Kshatriya - Kochaisa',
    'Kurmi Kshatriya - Kumar',
    'Kurmi Kshatriya - Kushwaha Koiri',
    'Kurmi Kshatriya - Mahata',
    'Kurmi Kshatriya - Mahato',
    'Kurmi Kshatriya - Mahto',
    'Kurmi Kshatriya - Parganiha',
    'Kurmi Kshatriya - Patel',
    'Kurmi Kshatriya - Prasad',
    'Kurmi Kshatriya - Rai',
    'Kurmi Kshatriya - Sachan',
    'Kurmi Kshatriya - Singh',
    'Kurmi Kshatriya - Verma',
    'Kuruba',
    'Kuruhina Shetty',
    'Kurumbar',
    'Kuruva',
    'Kushwaha',
    'Kutchi',
    'Kutchi Gurjar',
    'Lambadi',
    'Laxminarayan gola',
    'Leva Patidar',
    'Leva Patil',
    'Lingayat',
    'Lingayat - Agasa',
    'Lingayat - Akkasali',
    'Lingayat - Aradhya',
    'Lingayat - Balegala',
    'Lingayat - Banagar',
    'Lingayat - Banajiga',
    'Lingayat - Bhandari',
    'Lingayat - Bilijedaru',
    'Lingayat - Bilimagga',
    'Lingayat - Chaturtha',
    'Lingayat - Dikshwant',
    'Lingayat - Ganiga',
    'Lingayat - Gowda',
    'Lingayat - Gowli',
    'Lingayat - Gurav',
    'Lingayat - Hadapada',
    'Lingayat - Hatgar',
    'Lingayat - Hoogar / Hugar / Jeer',
    'Lingayat - Jadaru',
    'Lingayat - Jangam',
    'Lingayat - Kudu Vokkaliga',
    'Lingayat - Kumbar / Kumbara',
    'Lingayat - Kumbhar',
    'Lingayat - Kuruhina Setty',
    'Lingayat - lamba',
    'Lingayat - Lolagonda',
    'Lingayat - Madivala',
    'Lingayat - Malgar',
    'Lingayat - Mali',
    'Lingayat - Neelagar',
    'Lingayat - Neeli / Neelagar',
    'Lingayat - Neygi',
    'Lingayat - Nolamba',
    'Lingayat - Pancham',
    'Lingayat - Panchamasali',
    'Lingayat - Pattasali',
    'Lingayat - Reddy Reddi',
    'Lingayat - Sadar',
    'Lingayat - Sajjan / Sajjanaganigar',
    'Lingayat - Setty',
    'Lingayat - Shilwant',
    'Lingayat - Shiva Simpi',
    'Lingayat - Vani',
    'Lingayat - Veerashaiva',
    'Lohana',
    'Lohana - Ghoghari',
    'Lohana - Halai',
    'Lohana - Kutchi',
    'Lohana - Vaishnav',
    'Lohar',
    'Lubana',
    'Madiga',
    'Mahar',
    'Mahendra',
    'Maheshwari',
    'Mahindra',
    'Mahisya',
    'Majabi Mazhbi',
    'Mala',
    'Mali',
    'Mallah',
    'Mallah - Kewat / Keot',
    'Mallah - Nishad',
    'Manikpuri',
    'Manipuri',
    'Manjhi',
    'Mannan / Velon / Vannan',
    'Mapila',
    'Maratha',
    'Maratha - 96 Kuli Maratha',
    'Maratha - 96K Kokanastha',
    'Maratha - Aramari Gabit',
    'Maratha - Deshastha Maratha',
    'Maratha - Deshmukh',
    'Maratha - Deshtha Maratha',
    'Maratha - Gomantak Maratha',
    'Maratha - Jhadav',
    'Maratha - Kokanastha Maratha',
    'Maratha - Kunbi Dhanoje',
    'Maratha - Kunbi Khaire',
    'Maratha - Kunbi Khedule',
    'Maratha - Kunbi Lonari',
    'Maratha - Kunbi Maratha',
    'Maratha - Kunbi Tirale',
    'Maratha - Malwani',
    'Maratha - Maratha Kshatriya',
    'Maratha - Parit',
    'Maratha - Patil',
    'Maratha - Sonar',
    'Maratha - Suthar',
    'Maratha - Vani',
    'Maravar',
    'Maruthuvar',
    'Matang',
    'Maurya',
    'Maurya - Kachchi',
    'Maurya - Kushwaha',
    'Meda',
    'Meena',
    'Meenavar',
    'Meghwal',
    'Mehra',
    'Mehtar',
    'Menon',
    'Meru',
    'Meru darji',
    'Mochi',
    'Modak',
    'Mogaveera',
    'Monchi',
    'Motati Reddy',
    'Mudaliar',
    'Mudaliar - Agamudayar/Arcot/Thuluva Vellala',
    'Mudaliar - Isai Vellalar',
    'Mudaliar - Kerala Mudali',
    'Mudaliar - Kongu Vellala Gounder',
    'Mudaliar - Mudailiar Arcot',
    'Mudaliar - Mudaliar All',
    'Mudaliar - Mudaliar Saiva',
    'Mudaliar - Mudaliar Sengupta',
    'Mudaliar - Saiva Pillai Tirunelveli',
    'Mudaliar - Sengunthar/Kaikolar',
    'Mudaliar - Sozhiya Vellalar',
    'Mudaliar - Thondai Mandala Vellala',
    'Mudaliar - Veerakodi Vellala',
    'Mudaliar Arcot',
    'Mudiraj',
    'Muthuraja',
    'Naagavamsam',
    'Nadar',
    'Nadar - Kongu Nadar',
    'Nagaralu',
    'Naicker',
    'Naicker - Naicker others',
    'Naicker - Naicker-Vanniya Kula Kshatriyar',
    'Naicker - Rajaka Chakali Dhobi',
    'Naidu',
    'Naidu - Balija Naidu',
    'Naidu - Ediga /Goud',
    'Naidu - Gajula Kavarai',
    'Naidu - Gavara',
    'Naidu - Kamma',
    'Naidu - Kapu Naidu',
    'Naidu - Munnuru Kapu',
    'Naidu - Mutharaja',
    'Naidu - Perika',
    'Naidu - Raja Kambalathu Naicker',
    'Naidu - Raju',
    'Naidu - Reddy',
    'Naidu - Shetty Balija',
    'Naidu - Surya Balija',
    'Naidu - Telaga',
    'Naidu - Turupu Kapu',
    'Naidu - Vada Balija',
    'Naidu - Vadugan',
    'Naidu - Velama',
    'Naidu - Yadava Naidu',
    'Naik Nayak Nayaka',
    'Nair',
    'Nair - Adiyodi',
    'Nair - Anthur',
    'Nair - Chekkala Nair',
    'Nair - Illam',
    'Nair - Kaimal',
    'Nair - Kartha',
    'Nair - Kiryathil',
    'Nair - Kurup',
    'Nair - Maniyani',
    'Nair - Mannadiar',
    'Nair - Marar',
    'Nair - Menon',
    'Nair - Nair All',
    'Nair - Nair-Vaniya',
    'Nair - Nambiar',
    'Nair - Panicker',
    'Nair - Pillai',
    'Nair - Poduval',
    'Nair - Thampi',
    'Nair - Tharakan',
    'Nair - Unnithan',
    'Nair - Vellala Pillai',
    'Nair - Veluthedathu',
    'Nair - Vilakkithala',
    'Nair Veluthedathu',
    'Nair Vilakkithala',
    'Namasudra / Namosudra',
    'Nambiar',
    'Nambisan',
    'Namboodiri',
    'Namdev Chhipa',
    'Nandiwale',
    'Napit',
    'Nath Jogi',
    'Nayee (Barber)',
    'Nepali',
    'Nessi / Kurni',
    'Nhavi',
    'Nonia',
    'OBC',
    'Odan',
    'Oraon',
    'Oswal',
    'Otari',
    'Others',
    'Padmashali',
    'Padmashali - Devanga',
    'Padmashali - Jaandra',
    'Padmashali - Kaikaala',
    'Padmashali - Karakala Bhakthula',
    'Padmashali - Karni Bhakthula',
    'Padmashali - Kurni',
    'Padmashali - Neeli Saali',
    'Padmashali - Nessi',
    'Padmashali - Pattusali',
    'Padmashali - Shettigar',
    'Padmashali - Swakula Saali',
    'Padmashali - Thogata Veerakshathriya',
    'Pal',
    'Panan',
    'Panchal',
    'Panchamsali',
    'Pandaram',
    'Panicker',
    'Pano',
    'Paravan Bhartar',
    'Parkava Kulam',
    'Parvatha Rajakulam',
    'Pasi',
    'Paswan / Dusadh',
    'Patel',
    'Patel - Anjana (Chowdary) Patel',
    'Patel - Desai',
    'Patel - Dodia',
    'Patel - Kadava Patel',
    'Patel - Leva Patel',
    'Patel - Matia Patel',
    'Patel - Patel Desai',
    'patel Dodia',
    'patel Kadva',
    'patel Leva',
    'patel Leva - 27 Gam',
    'patel Leva - Baavis Gam',
    'patel Leva - Chaa Gam',
    'patel Leva - Chovis Gam',
    'patel Leva - Mota Sattavi',
    'patel Leva - Nani Sattavi',
    'patel Leva - Panch Gam',
    'Patel Lodhi',
    'Pathare Prabhu',
    'Patnaick',
    'Patra',
    'Perika',
    'Pillai',
    'Pillai - Aaru Nattu Vellala',
    'Pillai - Agamudayar/Arcot/Thuluva Vellala',
    'Pillai - Cherakula Vellalar',
    'Pillai - Desikar',
    'Pillai - Desikar Thanjavur',
    'Pillai - Illaththu Pillai',
    'Pillai - Isai Vellalar',
    'Pillai - Karkathar',
    'Pillai - Kodikal Pillai',
    'Pillai - Nanjil',
    'Pillai - Nanjil Mudali',
    'Pillai - Nankudi Vellalar',
    'Pillai - Othuvaar',
    'Pillai - Pandiya Vellalar',
    'Pillai - Saiva Pillai Thanjavur',
    'Pillai - Saiva Pillai Tirunelvi',
    'Pillai - Sengunthar/Kaikolar',
    'Pillai - Sozhiya Vellalar',
    'Pillai - Thondai Mandala Vellala',
    'Pillai - Veerakodi Vellala',
    'Pillai - Vellala Pillai',
    'Pollon Devandra Kula Vellalan',
    'Ponan',
    'Poosala',
    'Poundra',
    'Prajapati',
    'Pulaya Chruman',
    'Rabari',
    'Raigar',
    'Raikwar',
    'Rajaka',
    'Rajaka - Rajaka Vannar',
    'Rajbhar',
    'Rajbongshi',
    'Rajpurohit',
    'Rajput',
    'Rajput - Aheria Rajput',
    'Rajput - Baghel',
    'Rajput - Bais',
    'Rajput - Bankawat',
    'Rajput - Bargujar',
    'Rajput - Bhadauria',
    'Rajput - Bharbhunja',
    'Rajput - Bhatti',
    'Rajput - Bhriguvansha',
    'Rajput - Bisen',
    'Rajput - Bisht',
    'Rajput - Chandel',
    'Rajput - Chandravanshi',
    'Rajput - Chandrawat',
    'Rajput - Chauhan',
    'Rajput - Chawda / Chavada',
    'Rajput - Chib',
    'Rajput - Chundawat',
    'Rajput - Dhakare',
    'Rajput - Dixit',
    'Rajput - Doad',
    'Rajput - Dogra',
    'Rajput - Durgavanshi',
    'Rajput - Gahlot',
    'Rajput - Garhwal',
    'Rajput - Gautam',
    'Rajput - Gogawat',
    'Rajput - Gohil',
    'Rajput - Goud / Gaur',
    'Rajput - Jadeja',
    'Rajput - Jadon',
    'Rajput - Jamwal',
    'Rajput - Janjua',
    'Rajput - Jasrotia',
    'Rajput - Jaswal',
    'Rajput - Jhala',
    'Rajput - Kachwaha',
    'Rajput - Kalyat',
    'Rajput - Katoch',
    'Rajput - Kaushik',
    'Rajput - Khadagvanshi Khagi',
    'Rajput - Khangarot',
    'Rajput - Khati',
    'Rajput - Kirar',
    'Rajput - Kumaoni',
    'Rajput - Kuruvanshi',
    'Rajput - Kushwaha',
    'Rajput - Lodhi Rajput',
    'Rajput - Loniya Lonia Lunia',
    'Rajput - Madad',
    'Rajput - Mahror',
    'Rajput - Mahthan',
    'Rajput - Mahyavanshi',
    'Rajput - Manhas',
    'Rajput - Nagvanshi',
    'Rajput - Naruka',
    'Rajput - Nathawat',
    'Rajput - Negi',
    'Rajput - Nikumbh',
    'Rajput - Oad Rajput',
    'Rajput - Parihar',
    'Rajput - Parmar',
    'Rajput - Pratihar',
    'Rajput - Pundir',
    'Rajput - Raghuvanshi',
    'Rajput - Rajawal',
    'Rajput - Rajput Swarnkar',
    'Rajput - Rana',
    'Rajput - Rao',
    'Rajput - Rathore',
    'Rajput - Rawa Rajput',
    'Rajput - Rawal',
    'Rajput - Rohilla',
    'Rajput - Sagar Rajput',
    'Rajput - Sainthwar Rajput',
    'Rajput - Sarangdevot',
    'Rajput - Shakya',
    'Rajput - Shekhawat',
    'Rajput - Sikarwar',
    'Rajput - Singh',
    'Rajput - Sisodia',
    'Rajput - Solanki',
    'Rajput - Somvansha',
    'Rajput - Surwar',
    'Rajput - Suryavanshi',
    'Rajput - Tanwar',
    'Rajput - Thakur',
    'Rajput - Tomar',
    'Rajput - Ujjain',
    'Rajput - Vaishhya',
    'Rajput Garhwali',
    'Rajput Kumaoni',
    'Rajput Rohella Tank',
    'Ramdasia',
    'Ramgarhia',
    'Ramoshi Berad Bedar',
    'Ravidasia',
    'Rawat',
    'Reddy',
    'Reddy - Ayodhi',
    'Reddy - Bhoomanchi Reddy',
    'Reddy - Chowdary',
    'Reddy - Desuru',
    'Reddy - Gandla',
    'Reddy - Ganjam',
    'Reddy - Gone Kapu',
    'Reddy - Gudati',
    'Reddy - Kapu',
    'Reddy - Motati',
    'Reddy - Palle',
    'Reddy - Palnati',
    'Reddy - Panta',
    'Reddy - Pedakanti',
    'Reddy - Poknati',
    'Reddy - Reddiar',
    'Reddy - Sajjana',
    'Reddy - Vanni',
    'Reddy - Velanati',
    'Relli',
    'Rohit Chamar',
    'Ror',
    'Sadgope',
    'Saha',
    'Sahu',
    'Saini',
    'Saliya',
    'Samagar',
    'Sambava',
    'Santhali',
    'Sargara',
    'Sathwara',
    'Satnami',
    'Savji',
    'Sawantwadi',
    'Scheduled Caste',
    'Scheduled Tribe',
    'Senai Thalaivar',
    'Senguntha Mudaliyar',
    'Settibalija',
    'Shah',
    'Shilpkar',
    'Shimpi',
    'Silawat',
    'Sillekyatha',
    'Sindhi',
    'Sindhi - Sindhi Amil',
    'Sindhi - Sindhi Baibhand',
    'Sindhi - Sindhi Bhanusali',
    'Sindhi - Sindhi Bhatia',
    'Sindhi - Sindhi Chhapru',
    'Sindhi - Sindhi Daru',
    'Sindhi - Sindhi Hydrabadi',
    'Sindhi - Sindhi Larai',
    'Sindhi - Sindhi Larkan',
    'Sindhi - Sindhi Larkana',
    'Sindhi - Sindhi Lohana',
    'Sindhi - Sindhi Rohiri',
    'Sindhi - Sindhi Sahiti',
    'Sindhi - Sindhi Sakkhar',
    'Sindhi - Sindhi Sehwani',
    'Sindhi - Sindhi Shikarpuri',
    'Sindhi - Sindhi Thatai',
    'Siyal',
    'SKP',
    'Somvanshi',
    'Somvanshi Kayastha Prabhu',
    'Sonar / Sunar',
    'Sonkar',
    'Sourashtra',
    'Sozhiya Vellalar',
    'Srisayani',
    'SSK',
    'Subarna Banik',
    'Sudi Suri Sundhi Shaundik',
    'Sugali (Naika)',
    'Sutar',
    'Swakula sali',
    'Swarnkar',
    'Tamboli',
    'Tammali',
    'Tanti',
    'Tantuway',
    'Telaga',
    'Teli',
    'Thachar',
    'Thakkar',
    'Thandan',
    'Tharakan',
    'Thevar Mukkulathor',
    'Thevar Mukkulathor - Agamudayar',
    'Thevar Mukkulathor - Ambalakarar',
    'Thevar Mukkulathor - Appanad Kondayamkottai Maravar',
    'Thevar Mukkulathor - Easanattu Kallar',
    'Thevar Mukkulathor - Kallar',
    'Thevar Mukkulathor - Maniyakarar',
    'Thevar Mukkulathor - Maravar',
    'Thevar Mukkulathor - Piramalai Kallar',
    'Thevar Mukkulathor - Rajakula Agamudayar',
    'Thevar Mukkulathor - Sembanad Maravar',
    'Thevar Mukkulathor - Servai',
    'Thevar Mukkulathor - Thanjavur Kallar',
    'Thevar Mukkulathor - Vallambar',
    'Thigala',
    'Thiyya',
    'Thiyya - Ezhava',
    'Thiyya - Kavuthiya',
    'Thiyya - Thiyya Thandan',
    'Tili',
    'Togata',
    'Tonk Kshatriya',
    'Tribe',
    'Turi',
    'Turupu Kapu',
    'Uppara',
    'Vadar',
    'Vaddera',
    'Vaduka',
    'Vaidiki Velanadu',
    'Vaish',
    'Vaish - Vaish Dhaneshawat',
    'Vaishnav',
    'Vaishnav - Bairagi Swami',
    'Vaishnav - Vaishnav Bhatia',
    'Vaishnav - Vaishnav Dishaval',
    'Vaishnav - Vaishnav Kapol',
    'Vaishnav - Vaishnav Khadyata',
    'Vaishnav - Vaishnav Lad',
    'Vaishnav - Vaishnav Modh',
    'Vaishnav - Vaishnav Porvad',
    'Vaishnav - Vaishnav Shrimali',
    'Vaishnav - Vaishnav Sorathaiya',
    'Vaishnav - Vaishnav Vania',
    'Vaishnav Vanik',
    'Vaishnava',
    'Vaishnava - Brahmin Sri Vaishnava',
    'Vaishnava - Chhatada Sri Vaishnava',
    'Vaishnava - Sri Vaishnava',
    'Vaishya',
    'Vaishya - Mahuri',
    'Vaishya - Mathur Vaishya',
    'Vaishya Vani',
    'Vallala',
    'Valluvar',
    'Valmiki',
    'Valuvan',
    'Vania',
    'Vaniya',
    'Vaniya - Nair-Vaniya',
    'Vaniya - Vaniya Chettiar',
    'Vanjara',
    'Vanjari',
    'Vankar',
    'Vannar',
    'Vannia Kula Kshatriyar',
    'Vannia Kula Kshatriyar - Arasu',
    'Vannia Kula Kshatriyar - Gounder',
    'Vannia Kula Kshatriyar - Naicker',
    'Vannia Kula Kshatriyar - Padayachi',
    'Vannia Kula Kshatriyar - Palli',
    'Vannia Kula Kshatriyar - Pandal',
    'Vannia Kula Kshatriyar - Urs',
    'Vannia Kula Kshatriyar - Vannia Reddiar',
    'Vannia Kula Kshatriyar - Vanniyar',
    'Vanniyar',
    'Variar',
    'Varshney',
    'Veershaiva Veera Saivam',
    'Velaan',
    'Velama',
    'Velama - Adivelama',
    'Velama - Koppula',
    'Velama - Padmanayaka',
    'Velama - Polinati',
    'Velama - Yellapa',
    'Velan',
    'Vellalar',
    'Vellalar - Aaru Nattu Vellala',
    'Vellalar - Agamudayar/Arcot/Thuluva Vellala',
    'Vellalar - Cherakula Vellalar',
    'Vellalar - Desikar',
    'Vellalar - Devendra Kula Vellalar',
    'Vellalar - Illaththu Pillai',
    'Vellalar - Isai Vellalar',
    'Vellalar - Karkathar',
    'Vellalar - Kodikal Pillai',
    'Vellalar - Kongu Vellala Gounder',
    'Vellalar - Nanjil Mudali',
    'Vellalar - Nanjil Nattu Vellalar',
    'Vellalar - Nanjil Vellalar',
    'Vellalar - Nankudi Vellalar',
    'Vellalar - Othuvaar',
    'Vellalar - Pandiya Vellalar',
    'Vellalar - Saiva Pillai Thanjavur',
    'Vellalar - Saiva Pillai Tirunelveli',
    'Vellalar - Saiva Vellalar',
    'Vellalar - Sengunthar/Kaikolar',
    'Vellalar - Sozhiya Vellalar',
    'Vellalar - Thondai Mandala Vellala',
    'Vellalar - Veerakodi',
    'Vellalar - Vellalar All',
    'Vettuva Gounder',
    'Vettuvan',
    'Vishwakarma',
    'Vishwakarma - Black Smith',
    'Vishwakarma - Carpentry (Vadrangi, Vadla)',
    'Vishwakarma - Goldsmiths',
    'Vishwakarma - Sculptor (Shilpi)',
    'Vokkaliga',
    'Vysya',
    'Wani',
    'Yadav / Yadava',
    'Yadav / Yadava - Aheer / Ahir',
    'Yadav / Yadava - Ala Golla',
    'Yadav / Yadava - Daddi',
    'Yadav / Yadava - Das',
    'Yadav / Yadava - Dhador',
    'Yadav / Yadava - Erragola',
    'Yadav / Yadava - Gadri / Gadariya',
    'Yadav / Yadava - Gauda',
    'Yadav / Yadava - Gawli',
    'Yadav / Yadava - Goal / Gola / Golla',
    'Yadav / Yadava - Gop / Gopal / Gopala',
    'Yadav / Yadava - Goriya',
    'Yadav / Yadava - Gwala',
    'Yadav / Yadava - Gwalvanshi',
    'Yadav / Yadava - Jadav',
    'Yadav / Yadava - Kohar',
    'Yadav / Yadava - Konar',
    'Yadav / Yadava - Korna',
    'Yadav / Yadava - Krishnauth',
    'Yadav / Yadava - Kurudas Gollas',
    'Yadav / Yadava - Kuruma',
    'Yadav / Yadava - Mandal',
    'Yadav / Yadava - Manjrauth',
    'Yadav / Yadava - Nandvanshi',
    'Yadav / Yadava - Pakanati',
    'Yadav / Yadava - Puja',
    'Yadav / Yadava - Raut',
    'Yadav / Yadava - Suryavanshi',
    'Yadav / Yadava - Thethwar',
    'Yadav / Yadava - Yadav Golla',
    'Yadav / Yadava - Yaduvanshi',
    'Yellapu',
  ],
  [SupportedLanguage.Hindi]: [],
  [SupportedLanguage.Marathi]: [],
  [SupportedLanguage.Gujarati]: [],
  [SupportedLanguage.Bengali]: [],
  [SupportedLanguage.Telugu]: [],
}
