import { SupportedLanguage } from '@/enum'
import { DropdownOption } from '@/types'

export const INCOME_OPTIONS: DropdownOption = {
  [SupportedLanguage.English]: [
    'No Income',
    '₹ 0 - 1 Lakh',
    '₹ 1 - 2 Lakh',
    '₹ 2 - 3 Lakh',
    '₹ 3 - 4 Lakh',
    '₹ 4 - 5 Lakh',
    '₹ 5 - 7.5 Lakh',
    '₹ 7.5 - 10 Lakh',
    '₹ 10 - 15 Lakh',
    '₹ 15 - 20 Lakh',
    '₹ 20 - 25 Lakh',
    '₹ 25 - 35 Lakh',
    '₹ 35 - 50 Lakh',
    '₹ 50 - 70 Lakh',
    '₹ 70 Lakh - 1 crore',
    '₹ 1 crore above',
  ],
  [SupportedLanguage.Hindi]: [
    'कोई आय नहीं',
    '₹ 0 - 1 लाख',
    '₹ 1 - 2 लाख',
    '₹ 2 - 3 लाख',
    '₹ 3 - 4 लाख',
    '₹ 4 - 5 लाख',
    '₹ 5 - 7.5 लाख',
    '₹ 7.5 - 10 लाख',
    '₹ 10 - 15 लाख',
    '₹ 15 - 20 लाख',
    '₹ 20 - 25 लाख',
    '₹ 25 - 35 लाख',
    '₹ 35 - 50 लाख',
    '₹ 50 - 70 लाख',
    '₹ 70 लाख - 1 करोड़',
    '₹ 1 करोड़ से अधिक',
  ],
  [SupportedLanguage.Marathi]: [
    'कोणतेही उत्पन्न नाही',
    '₹ ० - १ लाख',
    '₹ १ - २ लाख',
    '₹ २ - ३ लाख',
    '₹ ३ - ४ लाख',
    '₹ ४ - ५ लाख',
    '₹ ५ - ७.५ लाख',
    '₹ ७.५ - १० लाख',
    '₹ १० - १५ लाख',
    '₹ १५ - २० लाख',
    '₹ २० - २५ लाख',
    '₹ २५ - ३५ लाख',
    '₹ ३५ - ५० लाख',
    '₹ ५० - ७० लाख',
    '₹ ७० लाख - १ कोटी',
    '₹ १ कोटी पेक्षा जास्त',
  ],
  [SupportedLanguage.Gujarati]: [
    'કોઈ આવક નથી',
    '₹ 0 - 1 લાખ',
    '₹ 1 - 2 લાખ',
    '₹ 2 - 3 લાખ',
    '₹ 3 - 4 લાખ',
    '₹ 4 - 5 લાખ',
    '₹ 5 - 7.5 લાખ',
    '₹ 7.5 - 10 લાખ',
    '₹ 10 - 15 લાખ',
    '₹ 15 - 20 લાખ',
    '₹ 20 - 25 લાખ',
    '₹ 25 - 35 લાખ',
    '₹ 35 - 50 લાખ',
    '₹ 50 - 70 લાખ',
    '₹ 70 લાખ - 1 કરોડ',
    '₹ 1 કરોડ ઉપર',
  ],
  [SupportedLanguage.Bengali]: [
    'কোনো আয় নেই',
    '₹ ০ - ১ লাখ',
    '₹ ১ - ২ লাখ',
    '₹ ২ - ৩ লাখ',
    '₹ ৩ - ৪ লাখ',
    '₹ ৪ - ৫ লাখ',
    '₹ ৫ - ৭.৫ লাখ',
    '₹ ৭.৫ - ১০ লাখ',
    '₹ ১০ - ১৫ লাখ',
    '₹ ১৫ - ২০ লাখ',
    '₹ ২০ - ২৫ লাখ',
    '₹ ২৫ - ৩৫ লাখ',
    '₹ ৩৫ - ৫০ লাখ',
    '₹ ৫০ - ৭০ লাখ',
    '₹ ৭০ লাখ - ১ কোটি',
    '₹ ১ কোটি এবং তার উপরে',
  ],
  [SupportedLanguage.Telugu]: [
    'ఆదాయం లేదు',
    '₹ 0 - 1 లక్ష',
    '₹ 1 - 2 లక్షలు',
    '₹ 2 - 3 లక్షలు',
    '₹ 3 - 4 లక్షలు',
    '₹ 4 - 5 లక్షలు',
    '₹ 5 - 7.5 లక్షలు',
    '₹ 7.5 - 10 లక్షలు',
    '₹ 10 - 15 లక్షలు',
    '₹ 15 - 20 లక్షలు',
    '₹ 20 - 25 లక్షలు',
    '₹ 25 - 35 లక్షలు',
    '₹ 35 - 50 లక్షలు',
    '₹ 50 - 70 లక్షలు',
    '₹ 70 లక్షలు - 1 కోటి',
    '₹ 1 కోటి పైన',
  ],
}
