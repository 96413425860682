import { SupportedLanguage } from '@/enum'
import { DropdownOption } from '@/types'

export const GOTHRA_OPTIONS: DropdownOption = {
  [SupportedLanguage.English]: [
    'Bharadwaja',
    'Kashyapa',
    'Vashishta',
    'Atri',
    'Gautama',
    'Vishwamitra',
    'Agastya',
    'Jamadagni',
    'Bhrigu',
    'Angirasa',
    'Parashara',
    'Shandilya',
    'Kaushika',
    'Dhananjaya',
    'Harita',
    'Maitreya',
    'Mudgala',
    'Katyayana',
    'Kutsa',
    'Lomasha',
    'Saunaka',
    'Vatsa',
    'Vishnuvardhana',
    'Yajnavalkya',
    'Kapila',
    'Mandavya',
    'Markandeya',
    'Nandana',
    'Pulastya',
    'Pulaha',
    'Rishyashringa',
    'Sankritya',
    'Shrivatsa',
    'Suryadhwaja',
    'Upamanyu',
    'Vamadeva',
    'Vishnuvriddha',
    'Vyaghrapada',
    'Yaska',
  ],
  [SupportedLanguage.Hindi]: [
    'भरद्वाज',
    'कश्यप',
    'वशिष्ठ',
    'अत्रि',
    'गौतम',
    'विश्वामित्र',
    'अगस्त्य',
    'जमदग्नि',
    'भृगु',
    'अंगिरस',
    'पराशर',
    'शांडिल्य',
    'कौशिक',
    'धनंजय',
    'हरित',
    'मैत्रेय',
    'मुद्गल',
    'कात्यायन',
    'कुत्स',
    'लोमश',
    'शौनक',
    'वत्स',
    'विष्णुवर्धन',
    'याज्ञवल्क्य',
    'कपिल',
    'माण्डव्य',
    'मार्कण्डेय',
    'नंदन',
    'पुलस्त्य',
    'पुलह',
    'ऋष्यशृंग',
    'संकृत्य',
    'श्रीवत्स',
    'सूर्यध्वज',
    'उपमन्यु',
    'वामदेव',
    'विष्णुवृद्ध',
    'व्याघ्रपाद',
    'यास्क',
  ],
  [SupportedLanguage.Marathi]: [
    'भरद्वाज',
    'कश्यप',
    'वशिष्ठ',
    'अत्रि',
    'गौतम',
    'विश्वामित्र',
    'अगस्त्य',
    'जमदग्नि',
    'भृगु',
    'अंगिरस',
    'पराशर',
    'शांडिल्य',
    'कौशिक',
    'धनंजय',
    'हरित',
    'मैत्रेय',
    'मुद्गल',
    'कात्यायन',
    'कुत्स',
    'लोमश',
    'शौनक',
    'वत्स',
    'विष्णुवर्धन',
    'याज्ञवल्क्य',
    'कपिल',
    'मांडव्य',
    'मार्कंडेय',
    'नंदन',
    'पुलस्त्य',
    'पुलह',
    'ऋष्यश्रृंग',
    'सांक्रित्य',
    'श्रीवत्स',
    'सूर्यध्वज',
    'उपमन्यु',
    'वामदेव',
    'विष्णुवृद्ध',
    'व्याघ्रपाद',
    'यास्क',
  ],
  [SupportedLanguage.Gujarati]: [
    'ભરદ્વાજ',
    'કશ્યપ',
    'વશિષ્ઠ',
    'અત્રિ',
    'ગૌતમ',
    'વિશ્વામિત્ર',
    'અગસ્ત્ય',
    'જમદગ્નિ',
    'ભૃગુ',
    'અંગિરસ',
    'પરાશર',
    'શાંડિલ્ય',
    'કૌશિક',
    'ધનંજય',
    'હરિત',
    'મૈત્રેય',
    'મુદ્ગલ',
    'કાત્યાયન',
    'કુત્સ',
    'લોમશ',
    'શૌનક',
    'વત્સ',
    'વિષ્ણુવર્ધન',
    'યાજ્ઞવલ્ક્ય',
    'કપિલ',
    'માંડવ્ય',
    'માર્કંડેય',
    'નંદન',
    'પુલસ્ત્ય',
    'પુલહ',
    'ઋષ્યશૃંગ',
    'સંકૃત્ય',
    'શ્રીવત્સ',
    'સૂર્યધ્વજ',
    'ઉપમન્યુ',
    'વામદેવ',
    'વિષ્ણુવૃદ્ધ',
    'વ્યાઘ્રપાદ',
    'યાસ્ક',
  ],
  [SupportedLanguage.Bengali]: [
    'ভরদ্বাজ',
    'কাশ্যপ',
    'বিশ্ববিশেষ',
    'অত্রি',
    'গৌতম',
    'বিশ্বমিত্র',
    'অগস্ত্য',
    'জামদগ্নি',
    'ভৃগু',
    'অঙ্গিরাস',
    'পরাশর',
    'শাণ্ডিল্য',
    'কৌশিক',
    'ধনঞ্জয়',
    'হারিত',
    'মৈত্রেয়',
    'মুদগল',
    'কট্যায়ন',
    'কুত্স',
    'লোমশ',
    'সৌনক',
    'বৎস',
    'বিশ্ণুয়ার্ধন',
    'যাজ্ঞবল্ক্য',
    'কপিল',
    'মণ্ডব্য',
    'মার্কণ্ডেয়',
    'নন্দন',
    'পুলস্ত্য',
    'পুলহ',
    'ঋষ্যশৃঙ্গ',
    'সংক্রিত',
    'শ্রীবৎস',
    'সূর্যধ্বজ',
    'উপামন্যু',
    'বামদেব',
    'বিশ্ণু বৃদ্ধ',
    'ব্যাঘ্রপদ',
    'যস্ক',
  ],
  [SupportedLanguage.Telugu]: [
    'భారద్వాజ',
    'కశ్యప',
    'వశిష్ఠ',
    'అత్రి',
    'గౌతమ',
    'విశ్వామిత్ర',
    'అగస్త్య',
    'జమదగ్ని',
    'భృగు',
    'అంగిరస',
    'పరాశర',
    'శాండిల్య',
    'కౌశిక',
    'ధనంజయ',
    'హారిత',
    'మైత్రేయ',
    'ముద్గల',
    'కాత్యాయన',
    'కుత్స',
    'లోమశ',
    'శౌనక',
    'వత్స',
    'విష్ణువర్ధన',
    'యాజ్ఞవల్క్య',
    'కపిల',
    'మండవ్య',
    'మార్కండేయ',
    'నందన',
    'పులస్త్య',
    'పులహ',
    'ఋశ్యశృంగ',
    'శంకృత్య',
    'శ్రీవత్స',
    'సూర్యధ్వజ',
    'ఉపమన్యు',
    'వామదేవ',
    'విష్ణువృద్ధ',
    'వ్యాఘ్రపాద',
    'యాస్క',
  ],
}
