import { PRIMARY } from '@/constants/colors';
import { FacebookOutlined, Instagram } from '@mui/icons-material';
import { Box, Container, Link, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import logo from 'public/goldenlogo.png';
import { memo } from 'react';
const Footer: React.FC = () => {
  return <Box sx={{
    textAlign: 'center',
    p: 2,
    background: PRIMARY,
    mt: 8,
    overflow: 'hidden'
  }} data-sentry-element="Box" data-sentry-component="Footer" data-sentry-source-file="Footer.tsx">
      <Container maxWidth="lg" sx={{
      mt: 4
    }} data-sentry-element="Container" data-sentry-source-file="Footer.tsx">
        <Image src={logo} style={{
        maxWidth: '180px',
        height: 'auto',
        marginBottom: '16px'
      }} alt="Marriage Biodata Maker" data-sentry-element="Image" data-sentry-source-file="Footer.tsx" />
        <Stack direction={{
        xs: 'column',
        sm: 'row'
      }} sx={{
        mb: 2
      }} gap={2} justifyContent="center" data-sentry-element="Stack" data-sentry-source-file="Footer.tsx">
          <Link href="https://marriagebiodatamaker.app/about-us" color="secondary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
            About Us
          </Link>
          <Link href="https://marriagebiodatamaker.app/contact-us" color="secondary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
            Contact Us
          </Link>
          <Link href="https://marriagebiodatamaker.app/blog" color="secondary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
            Blog
          </Link>
          <Link href="https://marriagebiodatamaker.app/terms-and-conditions" color="secondary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
            Terms And Conditions
          </Link>
          <Link href="https://marriagebiodatamaker.app/disclaimer" color="secondary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
            Disclaimer
          </Link>
          <Link href="https://marriagebiodatamaker.app/privacy-policy" color="secondary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
            Privacy Policy
          </Link>
          <Link href="https://marriagebiodatamaker.app/refund-policy" color="secondary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
            Refund Policy
          </Link>
          <Link href="https://marriagebiodatamaker.app/shipping-policy" color="secondary" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
            Shipping and Delivery
          </Link>
        </Stack>
        <Stack direction="row" sx={{
        mb: 2
      }} gap={2} justifyContent="center" data-sentry-element="Stack" data-sentry-source-file="Footer.tsx">
          <Link href="https://www.instagram.com/marriagebiodatamaker.app" color="secondary" target="_blank" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
            <Instagram color="secondary" data-sentry-element="Instagram" data-sentry-source-file="Footer.tsx" />
          </Link>
          <Link href="https://www.facebook.com/marriagebiodatamakerapp" color="secondary" target="_blank" data-sentry-element="Link" data-sentry-source-file="Footer.tsx">
            <FacebookOutlined color="secondary" data-sentry-element="FacebookOutlined" data-sentry-source-file="Footer.tsx" />
          </Link>
        </Stack>
        <Typography sx={{
        mb: 2
      }} color="secondary" data-sentry-element="Typography" data-sentry-source-file="Footer.tsx">
          © {new Date().getFullYear()} Marriage Biodata Maker. All rights reserved.
        </Typography>
      </Container>
    </Box>;
};
export default memo(Footer);